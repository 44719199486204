import React, { useState, useEffect, useMemo, useCallback } from "react";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Pagination, PaginationProps, message } from "antd";
import {
  addBank,
  authorizeBank,
  deleteBank,
  getBanks,
} from "../../../api/bank/bank";
import { REACT_APP_PAYSTACK_PUBLIC_KEY } from "../../../utils/appdetails";
import { usePaystackPayment } from "react-paystack";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { DocumentIcon } from "@heroicons/react/24/outline";
import Addmoneymodal from "../addmoney/addmoneymodal";
import Transactionsfilter from "../transactions/transactionsfilter";
import { openNotificationWithIcon } from "../../../utils/helper";
import Createbank from "./createbank";
import Authorizationinfo from "./authorizationinfo";

const Banks = () => {
  const amount = 50;
  const [showPayment, setShowPayment] = useState(false);
  const [reference, setReference] = useState("");
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [addingBank, setAddingBank] = useState(false);
  const [showAddBank, setShowAddBank] = useState(false);
  const [otherFilters, setOtherFilters] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [currentBank, setCurrentBank] = useState("");
  const [deletingBank, setDeletingBank] = useState(false);
  const [authorizingBank, setAuthorizingBank] = useState(false);
  const [authorizationInfo, setAuthorizationInfo] = useState("");
  const [showAuthorizationInfo, setShowAuthorizationInfo] = useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const requestData = useMemo(() => {
    let realOtherFilters = {};
    if (otherFilters !== null) {
      realOtherFilters = otherFilters;
    }
    return {
      ...realOtherFilters,
      currency: "ngn",
      limit: otherFilters === null ? limit : 8,
      page: otherFilters === null ? currentPage : 1,
    };
  }, [user, limit, currentPage, otherFilters]);

  // const addUserBank = async () => {
  //   setAddingBank(true);
  //   try {
  //     const res = await addBank();
  //     const { status, data } = res.data;

  //     if (status === "success") {
  //       // openNotificationWithIcon("warning", "Add Bank", "You wull be charged ₦50 to add a new bank")
  //       setReference(data.bank_account_link_id);
  //       setTimeout(() => {
  //         setShowPayment(true);
  //       }, 1000);
  //     } else {
  //       message.warning(`Initiate funding, ${res.data.message}`);
  //     }

  //     setAddingBank(false);
  //   } catch (error) {
  //     setAddingBank(false);
  //     message.error("Something went wrong: Initiate add bank");
  //   }
  // };

  const closeAuthorizationInfo = () => {
    setAuthorizationInfo("");
    setShowAuthorizationInfo(false);
    resetFilters();
    reloadFetchBanks();
  };

  const closeShowFilter = () => [setShowFilter(false)];

  const resetFilters = () => {
    setOtherFilters(null);
    setCurrentPage(1);
    setLimit(8);
  };

  const reloadFetchBanks = () => {
    fetchBanks();
  };

  const closeShowAddBankModal = (refresh?: boolean) => {
    setShowAddBank(false);
    if (refresh) {
      resetFilters();
      reloadFetchBanks();
    }
  };

  const closeShowPayment = () => {
    setShowPayment(false);
  };

  const fetchBanks = useCallback(async () => {
    setLoading(true);

    try {
      const res = await getBanks({
        ...requestData,
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null && data !== undefined) {
          setBanks(data);
          setTotal(parseInt(res.data.counts));
        } else {
          setBanks([]);
        }
      } else {
        message.warning(`Get banks, ${res.data.message}`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Get banks");
    }
  }, [requestData]);

  useEffect(() => {
    fetchBanks();
    // eslint-disable-next-line
  }, [fetchBanks]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const runDeleteBank = async (bank_account_link_id: string) => {
    try {
      setDeletingBank(true);
      setCurrentBank(bank_account_link_id);
      const res = await deleteBank({
        bank_account_link_id,
        currency: "ngn",
      });

      const { status } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Delete Bank", res.data.message);
        resetFilters();
        fetchBanks();
      } else {
        message.warning(
          `Delete bank, ${res?.data?.message || "Something went wrong"}`
        );
      }

      setDeletingBank(false);
    } catch (error) {
      setLoading(false);
      setDeletingBank(false);
      message.error("Something went wrong: Delete bank");
    }
  };

  const runAuthorizeBank = async (bank_account_link_id: string) => {
    try {
      setAuthorizingBank(true);
      setCurrentBank(bank_account_link_id);

      const res = await authorizeBank({
        bank_account_link_id,
        currency: "ngn",
      });

      const { status } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Authorize Bank");
        setAuthorizationInfo(res?.data?.data?.message ?? res?.data?.message);
        setShowAuthorizationInfo(true);
        // resetFilters();
        // fetchBanks();
      } else {
        message.warning(
          `Authorize bank, ${res?.data?.message || "Something went wrong"}`
        );
      }

      setAuthorizingBank(false);
    } catch (error) {
      setLoading(false);
      setAuthorizingBank(false);
      message.error("Something went wrong: Authorize bank");
    }
  };

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Link Banks
          </h1>

          <span
            className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcoloryellow text-appcolorblue"
            onClick={() => {
              if (!addingBank && !loading) {
                setShowAddBank(true);
                // addUserBank();
              }
            }}
          >
            {addingBank && <LoadingOutlined />}
            ADD
          </span>
        </div>

        <div className="pt-4 pb-16">
          {/* <div className="flex flex-col items-center justify-center mt-2">
            <div className="w-11 h-11">
              <DocumentIcon className="cursor-pointer" />
            </div>
            <p className="text-center cursor-pointer font-poppins">
              {" "}
              Link Banks & Bank coming soon
            </p>
          </div> */}
          {loading ? (
            <>
              {[1, 2, 3].map((item) => (
                <div className="mb-4" key={`trans-preloader-${item}`}>
                  <Skeletonloader height={"7rem"} />
                </div>
              ))}
            </>
          ) : (
            <>
              {banks.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-2">
                  <div className="w-11 h-11">
                    <DocumentIcon className="cursor-pointer" />
                  </div>
                  <p className="cursor-pointer font-poppins">
                    No banks
                    {otherFilters === null ? " added" : " found"}
                  </p>

                  {otherFilters === null ? (
                    <p className="text-center cursor-pointer font-poppins">
                      Click on{" "}
                      <span
                        className="text-appcolorblue"
                        onClick={() => {
                          if (!addingBank && !loading) {
                            setShowAddBank(true);
                          }
                        }}
                      >
                        ADD BANK
                      </span>{" "}
                      to add a bank
                    </p>
                  ) : (
                    <p className="text-center cursor-pointer font-poppins">
                      <span
                        className="text-appcolorblue"
                        onClick={() => {
                          resetFilters();
                        }}
                      >
                        Reset Filter
                      </span>
                    </p>
                  )}
                </div>
              ) : (
                <>
                  <div>
                    <div className="flex justify-end mb-4">
                      <div className="flex items-center">
                        <span
                          className="text-sm font-bold text-appcolorblue"
                          onClick={() => {
                            if (!loading) {
                              setShowFilter(true);
                            }
                          }}
                        >
                          Filter
                        </span>
                        {otherFilters !== null && (
                          <span
                            className="pl-2 text-sm font-bold text-appcolorred"
                            onClick={() => {
                              if (!loading) {
                                setLimit(20);
                                setCurrentPage(1);
                                setOtherFilters(null);
                              }
                            }}
                          >
                            Clear Filter
                          </span>
                        )}
                      </div>
                    </div>

                    {banks.map((item: any) => (
                      <div
                        className="p-4 mb-6 rounded-lg shadow-lg bg-appcolorlightgray"
                        key={item?.bank_account_link_id}
                      >
                        <h1 className="mb-4 text-xl font-bold">{item?.name}</h1>

                        <h1 className="mb-2">{item.bank}</h1>

                        <div>
                          <p className="mb-1">
                            Account Number: {item?.account_no}
                          </p>

                          {item?.status === "Authorization" && (
                            <Button
                              size="small"
                              htmlType="submit"
                              className="my-2 custom-small-btn"
                              loading={
                                authorizingBank &&
                                currentBank === item?.bank_account_link_id
                              }
                              disabled={authorizingBank || deletingBank}
                              onClick={() => {
                                runAuthorizeBank(item?.bank_account_link_id);
                              }}
                            >
                              Authorize account
                            </Button>
                          )}
                        </div>

                        <div
                          className="flex items-center justify-center w-auto mt-2 cursor-pointer"
                          onClick={() => {
                            if (!deletingBank && !authorizingBank) {
                              runDeleteBank(item?.bank_account_link_id);
                            }
                          }}
                        >
                          {currentBank === item?.bank_account_link_id &&
                          deletingBank ? (
                            <LoadingOutlined className="text-red-500" />
                          ) : (
                            <DeleteOutlined className="text-red-500" />
                          )}
                          <p className="pl-1 text-red-500">
                            {currentBank === item?.bank_account_link_id &&
                            deletingBank
                              ? "Deleting "
                              : "Delete "}{" "}
                            Bank
                          </p>
                        </div>
                      </div>
                    ))}

                    {banks.length > 0 && (
                      <div className="mt-8 mb-4">
                        <Pagination
                          defaultCurrent={currentPage}
                          defaultPageSize={limit}
                          total={total}
                          onChange={onChange}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {showAddBank && (
        <Createbank visible={showAddBank} closeModal={closeShowAddBankModal} />
      )}

      {showAuthorizationInfo && (
        <Authorizationinfo
          visible={showAuthorizationInfo}
          closeModal={closeAuthorizationInfo}
          info={authorizationInfo}
        />
      )}

      {showFilter && (
        <Transactionsfilter
          visible={showFilter}
          closeModal={closeShowFilter}
          setOtherFilters={setOtherFilters}
          isBanks
        />
      )}
    </div>
  );
};

export default Banks;
