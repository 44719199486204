import { Alert, Button, Form, Modal, message } from "antd";
import React, { useState } from "react";
import { openNotificationWithIcon } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { createWallet } from "../../../api/wallet/wallets";
import Inputselect from "../../../components/inputselect/inputselect";

export interface CreatewalletmodalInterface {
  visible: boolean;
  closeModal: () => void;
  supportedCurrencies: {
    currency: string;
  }[];
  loading: boolean;
}

const Createwalletmodal = ({
  visible,
  closeModal,
  supportedCurrencies,
  loading,
}: CreatewalletmodalInterface) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({
    currency: "",
  });

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onFinish = async (values: any) => {
    setError(null);
    setSubmitting(true);
    try {
      const res = await createWallet({
        currency: values.currency,
      });

      const { status, message } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Create Wallet", message);
        navigate("/dashboard/wallet-success");
      } else {
        // openNotificationWithIcon("error", "Create Wallet", message);
        setError(message);
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      message.error("Something went wrong: Create wallets");
    }
  };

  return (
    <div>
      <Modal
        onCancel={submitting ? undefined : closeModal}
        onOk={submitting ? undefined : closeModal}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Create Wallet
            </h1>
          </div>

          {error != null && (
            <div className="mb-2">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form onFinish={onFinish} layout="vertical" form={form}>
            <Form.Item
              validateStatus={errors?.currency.length > 0 ? "error" : undefined}
              help={errors?.currency.length > 0 ? errors.currency : undefined}
              label="Currency"
              name="currency"
              rules={[
                {
                  required: true,
                  message: "Currency is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Choose Currency"
                updateField={updateField}
                name={"currency"}
                selectOptions={supportedCurrencies}
                optionHasValue={false}
                alternativeValue="currency"
                selectOptionLabel="Currency"
                loading={loading}
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="mb-2 "
                disabled={submitting || loading}
                loading={submitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default Createwalletmodal;
