import React, { useState, useEffect } from "react";
import { Form, Modal, Button, message } from "antd";
import {
  addMoneyInterface,
  paystackButtonInterface,
} from "./addmoney-interface";
import { usePaystackPayment } from "react-paystack";
import Inputamount from "../inputamount/inputamount";
import { REACT_APP_PAYSTACK_PUBLIC_KEY } from "../../utils/appdetails";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fundWallet } from "../../api/wallet/wallets";
import { updateReloadWallet } from "../../store/userSlice/userSlice";
import { useNavigate } from "react-router-dom";

const PaystackButton = ({
  showPayment,
  closeShowPayment,
  email,
  amount,
  closeModal,
  reference,
}: paystackButtonInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const config = {
    email,
    amount: amount * 100,
    publicKey: REACT_APP_PAYSTACK_PUBLIC_KEY,
    reference: reference,
  };

  const initializePayment = usePaystackPayment(config);

  /**
   * Useeffect for running the initializePayment function if showPayment is true
   */
  useEffect(() => {
    const onSuccess = (reference: any) => {
      dispatch(
        updateReloadWallet({
          reloadWallet: true,
        })
      );
      closeShowPayment();
      closeModal();
      navigate("/dashboard/addmoney-success");
    };

    const onClose = () => {
      closeShowPayment();
    };

    if (showPayment) {
      // @ts-ignore
      initializePayment(onSuccess, onClose);
    }

    // eslint-disable-next-line
  }, [showPayment, initializePayment, closeShowPayment, closeModal]);

  return <span />;
};

const Addmoney = ({ visible, closeModal }: addMoneyInterface) => {
  const [form] = Form.useForm();

  const [reference, setReference] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [amount, setAmount] = useState(0.0);
  const [errors, setErrors] = useState({
    amount: "",
  });

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const onFinish = async (values: any) => {
    const { amount } = values;
    setAmount(Number(amount));
    setLoading(true);

    try {
      const res = await fundWallet({
        amount,
        method: "others",
      });

      const { status, data } = res.data;

      if (status === "success") {
        // openNotificationWithIcon("warning", "Fund Wallet", "You will be charged ₦50 to add funds to your wallet")
        setReference(data.reference_code);
        setTimeout(() => {
          setShowPayment(true);
        }, 1000);
      } else {
        message.warning(`Initiate funding, ${res.data.message}`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Initiate funding");
    }
  };

  const closeShowPayment = () => {
    setShowPayment(false);
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  return (
    <div>
      <Modal
        onCancel={loading ? undefined : closeModal}
        onOk={loading ? undefined : closeModal}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Fund Wallet
            </h1>
          </div>

          <p className="mb-4 text-sm font-normal font-poppins text-appcolorgrey">
            Top up your wallet with your debit card.
          </p>

          <div>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                validateStatus={errors?.amount.length > 0 ? "error" : undefined}
                help={errors?.amount.length > 0 ? errors.amount : undefined}
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Amount is required",
                  },
                ]}
              >
                <Inputamount
                  name="amount"
                  updateField={updateField}
                  placeHolder="Enter amount"
                  currency="₦"
                  amountValue={form.getFieldValue("amount")}
                />
              </Form.Item>

              <Form.Item>
                <div className="">
                  <Button
                    htmlType="submit"
                    className="mb-2"
                    loading={loading}
                    disabled={loading}
                  >
                    Confirm & Fund
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>

        {showPayment && (
          <PaystackButton
            showPayment={showPayment}
            closeShowPayment={closeShowPayment}
            email={user?.email}
            amount={amount}
            closeModal={closeModal}
            reference={reference}
          />
        )}
      </Modal>
    </div>
  );
};

export default Addmoney;
