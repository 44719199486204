import axios, { AxiosResponse } from "axios";
import { REACT_APP_BASE_URL, REACT_APP_PUBLIC_KEY } from "../utils/appdetails";
// import dayjs from "dayjs";
// import { store } from '../core/store'
// import jwt_decode from "jwt-decode";
// import { loginUser } from "../core/actions/useractions/useractions";
import { handleUnAuthorized } from "./requesterror";
// import { store } from "../store/store";
// import { logoutUser } from "../store/userSlice/userSlice";

// export const REACT_APP_BASE_URL = "https://staging-api.frontedge.io/api/core/v1"

/**
 * Axios instance with auth
 */
export const http = axios.create({
  baseURL: REACT_APP_BASE_URL,
  // timeout: 5000,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // Authorization: `Bearer token_c3e56bbdb5edc56e1a7d719edc219838`,
  },
});

/**
 * Axios instance without auth
 */
export const httpNoAuth = axios.create({
  baseURL: REACT_APP_BASE_URL,
  // timeout: 5000,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${REACT_APP_PUBLIC_KEY}`,
  },
});

/**
 * Set token headers
 * @param token string
 */
export const setAuthToken = (token: string | null) => {
  if (token !== null) {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete http.defaults.headers.common["Authorization"];
  }
};

// get the redux state
// const state: any = persistStore.getState()
// const dispatchStore: any = store.dispatch

// const exp = state?.userReducer?.user?.exp
// const refreshToken = state?.userReducer?.refreshToken

// const exp: any = localStorage.getItem('exp')
// const refreshToken = localStorage.getItem('refreshToken')
// const token = localStorage.getItem('token')

/**
 * Request interceptor that handles refreash token
 */
// http.interceptors.request.use(async req => {
//   const isExpired = dayjs.unix(exp).diff(dayjs()) < 1

//   if (isExpired) return req

//   const res = await axios.post(`${REACT_APP_BASE_URL}/refresh`, {
//     refreshToken
//   }, {
//     headers: {
//       "Authorization": `Bearer ${token}`
//     }
//   })

//   const { access_token, refresh_token } = res.data.data

//   console.log('requesting new....')

//   const user: any = jwt_decode(access_token)
//   localStorage.setItem('token', access_token)
//   localStorage.setItem('refreshToken', refresh_token)
//   localStorage.setItem('exp', user.exp)

//   dispatchStore(loginUser({
//     success: true,
//     token: access_token,
//     user,
//     exp: user.exp,
//     refreshToken: refresh_token,
//   }))

//   return req
// })

/**
 * Handle Authrizatin failed
 */
// http.interceptors.response.use(undefined, (error: { response: undefined; }) => {
// 	console.log('error--', error)
//   if (error.response === undefined) {
//     handleUnAuthorized()
//   }
// else {
//   if (error.response.data.message === "Full authentication is required to access this resource") {
//     handleUnAuthorized()
//   }
// }

//   return Promise.reject(error)
// })
http.interceptors.response.use(
  (response: AxiosResponse) => {
    const { response_code, message } = response.data;

    if (
      response_code === 401 &&
      message !==
        "Unable to initiate deactivation process via SMS at the moment"
    ) {
      handleUnAuthorized();
      setTimeout(() => {
        window.location.assign("/login");
      }, 1000);
    }

    // Handle the response data here
    // You can modify the response or return a new Promise to handle it asynchronously
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);
