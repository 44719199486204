import React, { useState, useEffect, useMemo, useCallback } from "react";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { Pagination, PaginationProps, message } from "antd";
import { addCard, deleteCard, getCards } from "../../../api/card/card";
import { paystackButtonInterface } from "./cards-interface";
import { REACT_APP_PAYSTACK_PUBLIC_KEY } from "../../../utils/appdetails";
import { usePaystackPayment } from "react-paystack";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { DocumentIcon } from "@heroicons/react/24/outline";
import Createcard from "./createcard";
import Addmoneymodal from "../addmoney/addmoneymodal";
import Transactionsfilter from "../transactions/transactionsfilter";
import { openNotificationWithIcon } from "../../../utils/helper";

const PaystackButton = ({
  showPayment,
  closeShowPayment,
  email,
  amount,
  reference,
  reloadFetchCards,
}: paystackButtonInterface) => {
  const config: any = {
    email,
    amount: amount * 100,
    publicKey: REACT_APP_PAYSTACK_PUBLIC_KEY,
    reference: reference,
    channels: ["card"],
  };

  const initializePayment = usePaystackPayment(config);

  /**
   * Useeffect for running the initializePayment function if showPayment is true
   */
  useEffect(() => {
    const onSuccess = (reference: any) => {
      closeShowPayment();
      reloadFetchCards();
    };

    const onClose = () => {
      closeShowPayment();
    };

    if (showPayment) {
      // @ts-ignore
      initializePayment(onSuccess, onClose);
    }

    // eslint-disable-next-line
  }, [showPayment, initializePayment, closeShowPayment]);

  return <span />;
};

const Cards = () => {
  const amount = 50;
  const [showPayment, setShowPayment] = useState(false);
  const [reference, setReference] = useState("");
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [addingCard, setAddingCard] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [paymentLink, setPaymentLink] = useState<any>(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [otherFilters, setOtherFilters] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [currentCard, setCurrentCard] = useState("");
  const [deletingCard, setDeletingCard] = useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const requestData = useMemo(() => {
    let realOtherFilters = {};
    if (otherFilters !== null) {
      realOtherFilters = otherFilters;
    }
    return {
      ...realOtherFilters,
      currency: "ngn",
      limit: otherFilters === null ? limit : 8,
      page: otherFilters === null ? currentPage : 1,
    };
  }, [user, limit, currentPage, otherFilters]);

  // const addUserCard = async () => {
  //   setAddingCard(true);
  //   try {
  //     const res = await addCard();
  //     const { status, data } = res.data;

  //     if (status === "success") {
  //       // openNotificationWithIcon("warning", "Add Card", "You wull be charged ₦50 to add a new card")
  //       setReference(data.reference_code);
  //       setTimeout(() => {
  //         setShowPayment(true);
  //       }, 1000);
  //     } else {
  //       message.warning(`Initiate funding, ${res.data.message}`);
  //     }

  //     setAddingCard(false);
  //   } catch (error) {
  //     setAddingCard(false);
  //     message.error("Something went wrong: Initiate add card");
  //   }
  // };

  const closeShowFilter = () => [setShowFilter(false)];

  const closeShowPaymentModal = () => {
    setShowPaymentModal(false);
  };

  const closeShowAddCardModal = () => {
    setShowAddCard(false);
  };

  const closeShowPayment = () => {
    setShowPayment(false);
  };

  const fetchCards = useCallback(async () => {
    setLoading(true);

    try {
      const res = await getCards({
        ...requestData,
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null && data !== undefined) {
          setCards(data);
          setTotal(parseInt(res.data.counts));
        } else {
          setCards([]);
        }
      } else {
        message.warning(`Get cards, ${res.data.message}`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Get cards");
    }
  }, [requestData]);

  useEffect(() => {
    fetchCards();
    // eslint-disable-next-line
  }, [fetchCards]);

  const reloadFetchCards = () => {
    fetchCards();
  };

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const resetFilters = () => {
    setOtherFilters(null);
    setCurrentPage(1);
    setLimit(8);
  };

  const runDeleteCard = async (reference_code: string) => {
    try {
      setDeletingCard(true);
      setCurrentCard(reference_code);
      const res = await deleteCard({
        reference_code,
      });

      const { status } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Delete Card", res.data.message);
        resetFilters();
        fetchCards();
      } else {
        message.warning(
          `Delete card, ${res?.data?.message || "Something went wrong"}`
        );
      }

      setDeletingCard(false);
    } catch (error) {
      setLoading(false);
      setDeletingCard(false);
      message.error("Something went wrong: Delete card");
    }
  };

  return (
    <div className="mt-2">
      {/* <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-16" />
      </div> */}

      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Link Cards
          </h1>

          <span
            className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcoloryellow text-appcolorblue"
            onClick={() => {
              if (!addingCard && !loading) {
                setShowAddCard(true);
                // addUserCard();
              }
            }}
          >
            {addingCard && <LoadingOutlined />}
            ADD
          </span>
        </div>

        <div className="pt-4 pb-16">
          {/* <div className="flex flex-col items-center justify-center mt-2">
            <div className="w-11 h-11">
              <DocumentIcon className="cursor-pointer" />
            </div>
            <p className="text-center cursor-pointer font-poppins">
              {" "}
              Link Cards & Bank coming soon
            </p>
          </div> */}
          {loading ? (
            <>
              {[1, 2, 3].map((item) => (
                <div className="mb-4" key={`trans-preloader-${item}`}>
                  <Skeletonloader height={"7rem"} />
                </div>
              ))}
            </>
          ) : (
            <>
              {cards.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-2">
                  <div className="w-11 h-11">
                    <DocumentIcon className="cursor-pointer" />
                  </div>
                  <p className="cursor-pointer font-poppins">
                    No cards
                    {otherFilters === null ? " added" : " found"}
                  </p>

                  {otherFilters === null ? (
                    <p className="text-center cursor-pointer font-poppins">
                      Click on{" "}
                      <span
                        className="text-appcolorblue"
                        onClick={() => {
                          if (!addingCard && !loading) {
                            setShowAddCard(true);
                          }
                        }}
                      >
                        ADD CARD
                      </span>{" "}
                      to add a card
                    </p>
                  ) : (
                    <p className="text-center cursor-pointer font-poppins">
                      <span
                        className="text-appcolorblue"
                        onClick={() => {
                          resetFilters();
                        }}
                      >
                        Reset Filter
                      </span>
                    </p>
                  )}
                </div>
              ) : (
                <>
                  <div>
                    <div className="flex justify-end mb-4">
                      <div className="flex items-center">
                        <span
                          className="text-sm font-bold text-appcolorblue"
                          onClick={() => {
                            if (!loading) {
                              setShowFilter(true);
                            }
                          }}
                        >
                          Filter
                        </span>
                        {otherFilters !== null && (
                          <span
                            className="pl-2 text-sm font-bold text-appcolorred"
                            onClick={() => {
                              if (!loading) {
                                setLimit(8);
                                setCurrentPage(1);
                                setOtherFilters(null);
                              }
                            }}
                          >
                            Clear Filter
                          </span>
                        )}
                      </div>
                    </div>

                    {cards.map((item: any) => (
                      <div
                        className="p-4 mb-6 rounded-lg shadow-lg bg-appcolorlightgray"
                        key={item?.reference_code}
                      >
                        <h1 className="mb-4 text-xl font-bold">
                          **** **** **** {item.last4}
                        </h1>

                        <h1 className="mb-2">{item.name}</h1>

                        <div>
                          <p className="mb-1">Card PAN: {item?.pan}</p>

                          <p>
                            {item.month} / {item.year}
                          </p>
                        </div>

                        <div
                          className="flex items-center w-auto mt-4 cursor-pointer"
                          onClick={() => {
                            if (!deletingCard) {
                              runDeleteCard(item?.reference_code);
                            }
                          }}
                        >
                          {currentCard === item?.reference_code &&
                          deletingCard ? (
                            <LoadingOutlined className="text-red-500" />
                          ) : (
                            <DeleteOutlined className="text-red-500" />
                          )}
                          <p className="pl-1 text-red-500">
                            {currentCard === item?.reference_code &&
                            deletingCard
                              ? "Deleting "
                              : "Delete "}{" "}
                            Card
                          </p>
                        </div>
                      </div>
                    ))}

                    {cards.length > 0 && (
                      <div className="mt-8 mb-4">
                        <Pagination
                          defaultCurrent={currentPage}
                          defaultPageSize={limit}
                          total={total}
                          onChange={onChange}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {showPaymentModal && (
        <Addmoneymodal
          visible={showPaymentModal}
          closeModal={closeShowPaymentModal}
          paymentLink={paymentLink}
        />
      )}

      {showAddCard && (
        <Createcard
          visible={showAddCard}
          closeModal={closeShowAddCardModal}
          setPaymentLink={setPaymentLink}
          setShowPaymentModal={setShowPaymentModal}
        />
      )}

      {showFilter && (
        <Transactionsfilter
          visible={showFilter}
          closeModal={closeShowFilter}
          setOtherFilters={setOtherFilters}
          isCards
        />
      )}

      {/* {showPayment && (
        <PaystackButton
          showPayment={showPayment}
          closeShowPayment={closeShowPayment}
          email={user?.email}
          amount={amount}
          reference={reference}
          reloadFetchCards={reloadFetchCards}
        />
      )} */}
    </div>
  );
};

export default Cards;
