import { isValidPhoneNumber } from "libphonenumber-js";
import { notification } from "antd";

/**
 * Validates phone number
 * @param  phoneNumber string
 * @returns boolean
 */
export const validatePhoneNumber = (phoneNumber: string | undefined) => {
  if (phoneNumber === undefined) {
    return false;
  } else {
    return isValidPhoneNumber(phoneNumber) && phoneNumber?.length === 14;
    // return true
  }
};

/**
 * Parse function for commaNumber
 * @param string
 * @param separator
 * @returns string
 */
const parse = (string: any, separator: any) => {
  let i = ((string.length - 1) % 3) + 1;

  if (i === 1 && string[0] === "-") {
    i = 4;
  }

  const strings = [string.slice(0, i)];

  for (; i < string.length; i += 3) {
    strings.push(separator, string.substr(i, 3));
  }

  return strings;
};

/**
 * Return a string with the provided number formatted with commas.
 *  Can specify either a Number or a String.
 * @param number || string inputNumber
 * @param optionalSeparator
 * @param optionalDecimalChar
 * @returns  string
 */
export const commaNumber = (
  inputNumber: number | string,
  optionalSeparator?: any,
  optionalDecimalChar?: any
) => {
  const decimalChar = optionalDecimalChar || ".";

  let stringNumber;

  {
    let number;

    switch (typeof inputNumber) {
      case "string":
        if (inputNumber.length < (inputNumber[0] === "-" ? 5 : 4)) {
          return inputNumber;
        }

        stringNumber = inputNumber;

        number = Number(
          decimalChar !== "."
            ? stringNumber.replace(decimalChar, ".")
            : stringNumber
        );
        break;

      case "number":
        stringNumber = String(inputNumber);
        number = inputNumber;

        if ("." !== decimalChar && !Number.isInteger(inputNumber)) {
          stringNumber = stringNumber.replace(".", decimalChar);
        }
        break;

      default:
        return inputNumber;
    }

    if (
      (-1000 < number && number < 1000) ||
      isNaN(number) ||
      !isFinite(number)
    ) {
      return stringNumber;
    }
  }

  {
    const decimalIndex = stringNumber.lastIndexOf(decimalChar);
    let decimal;
    if (decimalIndex > -1) {
      decimal = stringNumber.slice(decimalIndex);
      stringNumber = stringNumber.slice(0, decimalIndex);
    }

    const parts = parse(stringNumber, optionalSeparator || ",");

    if (decimal) {
      parts.push(decimal);
    }

    return parts.join("");
  }
};

/**
 * Manages notifications
 * @param  type string
 * @param  msg string
 * @param  desc string
 * @returns
 */
export const openNotificationWithIcon = (
  type: "success" | "error" | "warning" | "info",
  msg?: string,
  desc?: string | any
) => {
  switch (type) {
    case "success":
      return notification.success({
        message: msg,
        description: desc,
      });
    case "error":
      return notification.error({
        message: msg,
        description: desc,
      });
    case "warning":
      return notification.warning({
        message: msg,
        description: desc,
      });
    default:
      return notification.info({
        message: msg,
        description: desc,
      });
  }
};

/**
 * Capitalize first text of a string
 * @param  value string
 * @returns string
 */
export const capitalizeTextChar = (value?: string) => {
  if (value === undefined) {
    return "";
  } else {
    const capitalizedText = value.charAt(0).toUpperCase();
    const restOfString = value.slice(1);
    return capitalizedText + restOfString;
  }
};

export const transformVerificationKey = (value: string) => {
  switch (value) {
    case "bvn":
      return "BVN";
    case "email":
      return "Email";
    case "phone":
      return "Phone";
    case "identity_document":
      return "Verify Identity";
    case "card":
      return "Card Added";
    case "next_of_kin_primary":
      return "Primary Next of Kin";
    case "next_of_kin_secondary":
      return "Secondary Next of Kin";
    case "signature":
      return "Signature";
    case "guarantor":
      return "Guarantor";

    default:
      return value;
  }
};

export const transformVerificationRoute = (value: string) => {
  switch (value) {
    case "bvn":
      return "";
    case "email":
      return "/dashboard/confirm-otp";
    case "phone":
      return "/dashboard/confirm-otp";
    case "identity_document":
      return "/dashboard/continue-kyc";
    case "card":
      return "/dashboard/linked-cards";
    case "next_of_kin_primary":
      return "/dashboard/relationship";
    case "next_of_kin_secondary":
      return "/dashboard/relationship";
    case "signature":
      return "/dashboard/documents";
    case "guarantor":
      return "/dashboard/relationship";
    default:
      return "";
  }
};

export const transformCurrencyToSymbol = (value: string | undefined) => {
  switch (value) {
    case "ngn":
      return "₦";
    case "usd":
      return "$";
    case undefined:
      return "";
    default:
      return value?.toLocaleUpperCase();
  }
};

const checkNumberRegex = /^\d+$/;

export const validateAccountNumber = (value: string, limit = 10) => {
  let error = "";
  if (!checkNumberRegex.test(value)) {
    error = "Must contain only numbers";
  } else if (value.length < limit || value.length > limit) {
    error = `Must be a ${limit} digit number`;
  } else {
    error = "";
  }
  return error;
};

export const convertToLoweCaseAndTrim = (value: string) => {
  if (value === undefined || value === null) {
    return "";
  }
  return value.trim().toLowerCase();
};

interface ObjectItem {
  parameter: string;
  status: string;
  class: string;
  option: string[] | string;
  type?: string;
  created_on?: string;
  updated_on?: string;
}

export const updateCommonItems = (
  firstArray: ObjectItem[],
  secondArray: ObjectItem[]
): ObjectItem[] => {
  const updatedArray = firstArray.map((firstItem) => {
    const matchingSecondItem = secondArray.find(
      (secondItem) => secondItem.parameter === firstItem.parameter
    );

    return matchingSecondItem
      ? { ...firstItem, ...matchingSecondItem }
      : firstItem;
  });

  return updatedArray;
};

export const checkAmount = (
  amount: number,
  minAmount: number,
  maxAmount: number
) => {
  if (Number(amount) < Number(minAmount)) {
    return `The amount is less than the minimum allowed of ₦${commaNumber(
      minAmount
    )}`;
  } else if (Number(amount) > Number(maxAmount)) {
    return `The amount is greater than the maximum allowed of ₦${commaNumber(
      maxAmount
    )}`;
  } else {
    return "";
  }
};

export const convertToSlug = (str?: string) => {
  if (str === undefined) {
    return "";
  }

  return str
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};

export const validateNumberAbove = (
  value: number | string | undefined,
  aboveNumber: number,
  numberNaming?: string
) => {
  if (value !== undefined) {
    if (Number(value) <= Number(aboveNumber)) {
      return `Invalid ${numberNaming ?? "number"}`;
    }
    return "";
  }
  return `Invalid ${numberNaming ?? "number"}`;
};
