import { http } from "..";
import {
  billPaymentRequestPayload,
  getBillPaymentProvidersPayload,
  verifyBillPaymentPayload,
} from "./billpayment-interface";

export const getBillPaymentProviders = (
  billType: string,
  data: getBillPaymentProvidersPayload
) => {
  return http.post(`/service/${billType}/options`, {
    ...data,
  });
};

export const verifyBillPayment = (
  billType: string,
  data: verifyBillPaymentPayload
) => {
  return http.post(`/service/${billType}/verify-id`, {
    ...data,
  });
};

export const billPaymentRequest = (
  billType: string,
  data: billPaymentRequestPayload
) => {
  return http.post(`/service/${billType}/request`, {
    ...data,
  });
};
