import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Form,
  Input,
  Pagination,
  PaginationProps,
  message,
} from "antd";
import { nanoid } from "nanoid";
import {
  createBeneficiary,
  getBeneficiaries,
  getSupportedBanks,
  getSupportedBanksInstitutions,
  getSupportedCurrencies,
  removeBeneficiary,
} from "../../../api/wallet/wallets";
import {
  convertToLoweCaseAndTrim,
  openNotificationWithIcon,
  validateAccountNumber,
} from "../../../utils/helper";
import Inputselect from "../../../components/inputselect/inputselect";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import { DocumentIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { LoadingOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const beneficiaryTypes = [
  {
    label: "Moveon Beneficiary",
    value: "Internal",
  },
  {
    label: "Other Banks Beneficiary",
    value: "External",
  },
];

const Createbeneficiary = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [nameSearch, setNameSearch] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [currentlyDeleting, setCurrentlyDeleting] = useState("");
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [currentBeneficiaries, setCurrentBeneficiaries] = useState([]);
  const [loadingBeneficiaries, setLoadingBeneficiaries] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [loadingSupportedBanks, setLoadingSupportedBanks] = useState(false);
  const [supportedBanks, setSupportedBanks] = useState([]);
  const [loadingSupportedCurrencies, setLoadingSupportedCurrencies] =
    useState(false);
  const [supportedCurrencies, setSupportedCurrencies] = useState<
    { currency: string; country_code: string }[]
  >([]);
  const [error, setError] = useState(null);
  const [tabs, setTabs] = useState([
    {
      id: nanoid(),
      name: "Create",
      active: true,
    },
    {
      id: nanoid(),
      name: "List",
      active: false,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    currency: "",
    code: "",
    number: "",
  });

  const requestData = useMemo(() => {
    return {
      limit,
      page: currentPage,
      name:
        nameSearch === null
          ? undefined
          : nameSearch === ""
          ? undefined
          : nameSearch,
    };
  }, [limit, currentPage, nameSearch]);

  const onChange = debounce((value: string) => {
    if (value.trim().length > 0) {
      setNameSearch(value.trim());
      setLimit(20);
      setCurrentPage(1);
      // const inputValue = convertToLoweCaseAndTrim(value);
      // const filteredBeneficiaries = beneficiaries.filter(
      //   (item: any) =>
      //     convertToLoweCaseAndTrim(item?.name || "").includes(inputValue) ||
      //     convertToLoweCaseAndTrim(item?.number || "").includes(inputValue) ||
      //     convertToLoweCaseAndTrim(item?.bank || "").includes(inputValue)
      // );
      // setCurrentBeneficiaries(filteredBeneficiaries);
    } else {
      setNameSearch("");
      // setCurrentBeneficiaries(beneficiaries);
    }
  }, 300);

  const onChangePagination: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (name === "beneficiaryType") {
      form.setFieldsValue({
        identifier: undefined,
        currency: undefined,
        code: undefined,
        number: undefined,
      });
      setSupportedBanks([]);
    }

    if (name === "currency") {
      const getCurrentCurrency = supportedCurrencies.filter(
        (item) => item.currency === value
      );
      if (getCurrentCurrency.length > 0) {
        setCountryCode(getCurrentCurrency[0].country_code);
      }
    }
  };

  const fetchBeneficiaries = useCallback(async () => {
    setLoadingBeneficiaries(true);
    try {
      const res = await getBeneficiaries({
        currency: form.getFieldValue("currency"),
        beneficiaryType: form.getFieldValue("beneficiaryType"),
        ...requestData,
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        let newBeneficiaries: any[] = [];
        data.forEach((item: any) => {
          newBeneficiaries.push({
            ...item,
            beneficiaryLabel: `${item?.name} - ${item?.bank} ${item?.number}`,
          });
        });
        setBeneficiaries(data);
        setCurrentBeneficiaries(data);
        setTotal(parseInt(res.data.counts));
      } else {
        openNotificationWithIcon("error", "Beneficiaries", message);
      }

      setLoadingBeneficiaries(false);
    } catch (error) {
      setLoadingBeneficiaries(false);
      message.error("Something went wrong: Beneficiaries");
    }
  }, [requestData]);

  useEffect(() => {
    if (form.getFieldValue("beneficiaryType") !== undefined) {
      // form.setFieldValue("currency", supportedCurrencies[0].currency);
      fetchBeneficiaries();
    }

    // eslint-disable-next-line
  }, [
    fetchBeneficiaries,
    supportedCurrencies,
    form.getFieldValue("beneficiaryType"),
  ]);

  const switchTabs = (valueIndex: number) => {
    const newTabs = [...tabs];
    newTabs.forEach((item, index) => {
      item.active = false;
      if (valueIndex === index) {
        item.active = true;
      }
    });
    setTabs(newTabs);
    form.setFieldValue("currency", undefined);
    form.setFieldValue("code", undefined);
    form.setFieldValue("number", undefined);
    form.setFieldValue("beneficiaryType", undefined);
    form.setFieldValue("identifier", undefined);
    setErrors({
      currency: "",
      code: "",
      number: "",
    });
    setError(null);
    setCountryCode("");
    setError(null);

    // if (tabs[0].active) {
    //   setLimit(20);
    //   setCurrentPage(1);
    // }

    // if (tabs[1].active) {
    //   if (supportedCurrencies.length > 0) {
    //     form.setFieldValue("currency", supportedCurrencies[0].currency);
    //     fetchBeneficiaries();
    //   }
    // }
  };

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    if (form.getFieldValue("beneficiaryType") === "External") {
      newErrors["number"] = validateAccountNumber(values?.number);
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await createBeneficiary({
          ...values,
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Create Beneficiary", message);
          form.resetFields();
          setCountryCode("");
          setSupportedBanks([]);
          navigate("/dashboard/transfer");
        } else {
          setError(message);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong: Create Beneficiary");
      }
    } else {
      setLoading(false);
    }
  };

  const fetchAvailableCurrencies = async () => {
    setLoadingSupportedCurrencies(true);
    try {
      const res = await getSupportedCurrencies();

      const { status, message, data } = res.data;

      if (status === "success") {
        setSupportedCurrencies(data);
      } else {
        openNotificationWithIcon("error", "Supported currencies", message);
      }

      setLoadingSupportedCurrencies(false);
    } catch (error) {
      setLoadingSupportedCurrencies(false);
      message.error("Something went wrong:  Supported currencies");
    }
  };

  useEffect(() => {
    fetchAvailableCurrencies();
  }, []);

  const fetchAvailableBanks = async () => {
    setLoadingSupportedBanks(true);
    try {
      const res = await getSupportedBanksInstitutions({
        country_code: countryCode,
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        setSupportedBanks(data);
      } else {
        openNotificationWithIcon("error", "Supported Banks", message);
      }

      setLoadingSupportedBanks(false);
    } catch (error) {
      setLoadingSupportedBanks(false);
      message.error("Something went wrong:  Supported banks");
    }
  };

  useEffect(() => {
    form.setFieldValue("code", undefined);
    if (countryCode !== undefined && countryCode !== "") {
      if (tabs[0].active) {
        fetchAvailableBanks();
      } else {
        fetchBeneficiaries();
      }
    }
  }, [countryCode]);

  const deleteBeneficiaries = async (recipient_id: string) => {
    try {
      setDeleting(true);
      setCurrentlyDeleting(recipient_id);

      const res = await removeBeneficiary({
        recipient_id,
        currency: form.getFieldValue("currency"),
        beneficiaryType: form.getFieldValue("beneficiaryType"),
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Delete Beneficiary", message);
        fetchBeneficiaries();
        setCurrentlyDeleting("");
      } else {
        openNotificationWithIcon("error", "Delete Beneficiary", message);
      }
      setDeleting(false);
    } catch (error) {
      setDeleting(false);
      message.error("Something went wrong:  Delete beneficiary");
    }
  };

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Beneficiary
          </h1>
        </div>

        <div className="pb-16">
          <div className="grid grid-cols-2 rounded-2xl bg-appcolorshade p-[0.37rem] w-full mx-auto mb-6">
            {tabs.map((item, index) => (
              <div
                className={`font-semibold text-xs text-center cursor-pointer py-4 font-poppins ${
                  item.active ? "bg-appcolorwhite rounded-xl" : "bg-transparent"
                }`}
                onClick={() => {
                  if (!loading) {
                    switchTabs(index);
                  }
                }}
                key={item.id}
              >
                {item.name}
              </div>
            ))}
          </div>

          {tabs[0].active ? (
            <>
              {error != null && (
                <div className="flex justify-center mb-4">
                  <Alert message={error} type="error" className="w-full" />
                </div>
              )}

              <Form onFinish={onFinish} layout="vertical" form={form}>
                <Form.Item
                  name={"beneficiaryType"}
                  label="Beneficiary Type"
                  rules={[
                    {
                      required: true,
                      message: "Beneficiary Type is required",
                    },
                  ]}
                >
                  <Inputselect
                    placeHolder="Choose Beneficiary Type"
                    updateField={updateField}
                    name={"beneficiaryType"}
                    selectOptions={beneficiaryTypes}
                    optionHasValue={false}
                    alternativeValue="value"
                    selectOptionLabel="label"
                  />
                </Form.Item>

                {form.getFieldValue("beneficiaryType") ===
                  beneficiaryTypes[0].value && (
                  <>
                    <Form.Item
                      label="Email or Phone or Wallet ID"
                      name="identifier"
                      rules={[
                        {
                          required: true,
                          message: "Email/Phone/Wallet ID is required",
                        },
                      ]}
                    >
                      <Input placeholder="Email/Phone/Wallet ID" />
                    </Form.Item>
                  </>
                )}

                {form.getFieldValue("beneficiaryType") ===
                  beneficiaryTypes[1].value && (
                  <>
                    <Form.Item
                      validateStatus={
                        errors?.currency.length > 0 ? "error" : undefined
                      }
                      help={
                        errors?.currency.length > 0
                          ? errors.currency
                          : undefined
                      }
                      label="Currency"
                      name="currency"
                      rules={[
                        {
                          required: true,
                          message: "Currency is required",
                        },
                      ]}
                    >
                      <Inputselect
                        placeHolder="Choose Currency"
                        updateField={updateField}
                        name={"currency"}
                        selectOptions={supportedCurrencies}
                        optionHasValue={false}
                        alternativeValue="currency"
                        selectOptionLabel="Currency"
                        loading={loadingSupportedCurrencies}
                      />
                    </Form.Item>

                    <Form.Item
                      validateStatus={
                        errors?.code.length > 0 ? "error" : undefined
                      }
                      help={errors?.code.length > 0 ? errors.code : undefined}
                      label="Bank"
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Bank is required",
                        },
                      ]}
                    >
                      <Inputselect
                        placeHolder="Choose Bank"
                        updateField={updateField}
                        name={"code"}
                        selectOptions={supportedBanks}
                        optionHasValue={false}
                        alternativeValue="code"
                        selectOptionLabel="name"
                        loading={loadingSupportedBanks}
                        showSearch={true}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Account Number"
                      name="number"
                      rules={[
                        {
                          required: true,
                          message: "Account Number is required",
                        },
                      ]}
                      validateStatus={
                        errors?.number.length > 0 ? "error" : undefined
                      }
                      help={
                        errors?.number.length > 0 ? errors.number : undefined
                      }
                    >
                      <Input
                        maxLength={10}
                        type="number"
                        placeholder=" Account Number"
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="mb-2 "
                    disabled={loading}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <div>
                <Form className="mt-4" layout="vertical">
                  <Form.Item
                    name={"beneficiaryType"}
                    label="Beneficiary Type"
                    rules={[
                      {
                        required: true,
                        message: "Beneficiary Type is required",
                      },
                    ]}
                  >
                    <Inputselect
                      placeHolder="Choose Beneficiary Type"
                      updateField={updateField}
                      name={"beneficiaryType"}
                      selectOptions={beneficiaryTypes}
                      optionHasValue={false}
                      alternativeValue="value"
                      selectOptionLabel="label"
                    />
                  </Form.Item>

                  {form.getFieldValue("beneficiaryType") ===
                    beneficiaryTypes[1].value && (
                    <Form.Item
                      validateStatus={
                        errors?.currency.length > 0 ? "error" : undefined
                      }
                      help={
                        errors?.currency.length > 0
                          ? errors.currency
                          : undefined
                      }
                      label="Currency"
                      name="currency"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Currency is required",
                      //   },
                      // ]}
                    >
                      <Inputselect
                        placeHolder="Choose Currency"
                        updateField={updateField}
                        name={"currency"}
                        selectOptions={supportedCurrencies}
                        optionHasValue={false}
                        alternativeValue="currency"
                        selectOptionLabel="Currency"
                        loading={loadingSupportedCurrencies}
                        // selectValue={form.getFieldValue("currency")}
                      />
                    </Form.Item>
                  )}
                </Form>

                <div>
                  <Inputwithaddon
                    disabled={loadingBeneficiaries}
                    updateField={(name: string, value: string) => {
                      onChange(value);
                    }}
                    name="search"
                    placeHolder="Search..."
                    addonBefore={
                      <div>
                        <MagnifyingGlassIcon className="w-5 h-5" />
                      </div>
                    }
                  />
                </div>

                <div className="mt-4">
                  <p className="mb-4 font-semibold font-poppins">
                    Beneficiary List
                  </p>
                  {loadingBeneficiaries ? (
                    <>
                      {[1, 2, 3].map((item) => (
                        <div className="mb-4" key={`trans-preloader-${item}`}>
                          <Skeletonloader height={"4rem"} />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {currentBeneficiaries.length === 0 ? (
                        <div className="flex flex-col items-center justify-center mt-2">
                          <div className="w-11 h-11">
                            <DocumentIcon className="cursor-pointer" />
                          </div>
                          <p className="text-center cursor-pointer font-poppins">
                            No Beneficiaries found.
                          </p>
                        </div>
                      ) : (
                        <div>
                          {currentBeneficiaries.map((item: any, index) => (
                            <div
                              className="flex justify-between mb-4"
                              key={`recipients-acc-${index}-${item?.recipient_id}`}
                            >
                              <p className="text-xs font-semibold font-poppins w-[50%]">
                                {item?.name}
                              </p>

                              <div className="flex flex-col items-end flex-1  w-[50%]">
                                <div className="flex text-xs">
                                  <span>{item?.bank}-</span>
                                  <span className="font-semibold">
                                    {item?.number}
                                  </span>
                                </div>

                                <span
                                  className="text-xs underline cursor-pointer text-appcolorred"
                                  onClick={() => {
                                    if (!deleting) {
                                      deleteBeneficiaries(item?.recipient_id);
                                    }
                                  }}
                                >
                                  Delete Beneficiary{" "}
                                  {currentlyDeleting === item?.recipient_id &&
                                    deleting && <LoadingOutlined />}
                                </span>
                              </div>
                            </div>
                          ))}

                          {currentBeneficiaries.length > 0 && (
                            <div className="mt-4 mb-4">
                              <Pagination
                                defaultCurrent={currentPage}
                                defaultPageSize={limit}
                                total={total}
                                onChange={onChangePagination}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Createbeneficiary;
