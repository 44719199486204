import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, message } from "antd";
import { openNotificationWithIcon } from "../../../utils/helper";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  createVirtualAccount,
  getSupportedBanks,
  getSupportedCurrencies,
} from "../../../api/wallet/wallets";

export interface CreatevirtualaccountInterface {
  visible: boolean;
  closeModal: (refresh?: boolean) => void;
  currency: string;
  loadingCurrencies: boolean;
  supportedCurrencies: {
    currency: string;
  }[];
}

const Createvirtualaccount = ({
  visible,
  closeModal,
  currency,
  loadingCurrencies,
  supportedCurrencies,
}: CreatevirtualaccountInterface) => {
  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [supportedBanks, setSupportedBanks] = useState([]);
  const [loadingSupportedBanks, setLoadingSupportedBanks] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({
    bank: "",
    currency: "",
  });

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onFinish = async (values: any) => {
    setError(null);
    setSubmitting(true);
    try {
      const res = await createVirtualAccount({
        // currency: values?.currency,
        bank: values?.bank,
      });

      const { status, message } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Create Wallet Account", message);
        // close and reload
      } else {
        // openNotificationWithIcon("error", "Create Wallet Account", message);
        setError(message);
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      message.error("Something went wrong: Create wallet account");
    }
  };

  const fetchAvailableBanks = async () => {
    setLoadingSupportedBanks(true);
    try {
      const res = await getSupportedBanks({
        currency: form.getFieldValue("currency"),
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        setSupportedBanks(data);
      } else {
        openNotificationWithIcon("error", "Supported Banks", message);
      }

      setLoadingSupportedBanks(false);
    } catch (error) {
      setLoadingSupportedBanks(false);
      message.error("Something went wrong:  Supported banks");
    }
  };

  useEffect(() => {
    if (form.getFieldValue("currency") !== undefined) {
      fetchAvailableBanks();
    }
  }, [form.getFieldValue("currency")]);

  return (
    <div>
      <Modal
        onCancel={
          submitting
            ? undefined
            : () => {
                closeModal();
              }
        }
        onOk={
          submitting
            ? undefined
            : () => {
                closeModal();
              }
        }
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Create Wallet Account
            </h1>
          </div>

          {error != null && (
            <div className="mb-2">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form onFinish={onFinish} layout="vertical" form={form}>
            <Form.Item
              validateStatus={errors?.currency.length > 0 ? "error" : undefined}
              help={errors?.currency.length > 0 ? errors.currency : undefined}
              label="Currency"
              name="currency"
              rules={[
                {
                  required: true,
                  message: "Currency is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Choose Currency"
                updateField={updateField}
                name={"currency"}
                selectOptions={supportedCurrencies}
                optionHasValue={false}
                alternativeValue="currency"
                selectOptionLabel="Currency"
                loading={loadingCurrencies}
              />
            </Form.Item>

            {form.getFieldValue("currency") !== undefined && (
              <Form.Item
                validateStatus={errors?.bank.length > 0 ? "error" : undefined}
                help={errors?.bank.length > 0 ? errors.bank : undefined}
                label="Bank"
                name="bank"
                rules={[
                  {
                    required: true,
                    message: "Bank is required",
                  },
                ]}
              >
                <Inputselect
                  placeHolder="Choose Bank"
                  updateField={updateField}
                  name={"bank"}
                  selectOptions={supportedBanks}
                  optionHasValue={false}
                  alternativeValue="bank"
                  selectOptionLabel="bank"
                  loading={loadingSupportedBanks}
                />
              </Form.Item>
            )}

            <Form.Item>
              <Button
                htmlType="submit"
                className="mb-2 "
                disabled={submitting || loadingSupportedBanks}
                loading={submitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default Createvirtualaccount;
