import React from "react";
import { Select } from "antd";
import { inputSelectInterface } from "./inputselect-interface";

const { Option } = Select;

interface EnhancedInputSelectInterface extends inputSelectInterface {
  searchInNodes?: boolean;
}

const Inputselect = ({
  hasError,
  placeHolder,
  selectOptions,
  updateField,
  name,
  showSearch,
  optionHasValue,
  loading,
  selectOptionLabel,
  selectValue,
  alternativeValue,
  isMultiple,
  icon,
  disabled,
  searchInNodes = false,
}: EnhancedInputSelectInterface) => {
  const getSearchText = (option: any): string => {
    if (typeof option.searchText === 'string') {
      return option.searchText;
    }
    return '';
  };

  // Add custom styles to ensure text wrapping and full visibility
  const selectStyles = {
    width: '100%',
  };

  return (
    <div className="flex w-full p-2 px-4 rounded-lg bg-appcolorshade input-select">
      {icon !== undefined && icon}
      <Select
        style={selectStyles}
        optionFilterProp={searchInNodes ? "searchText" : "children"}
        mode={isMultiple ? "multiple" : undefined}
        bordered={false}
        showSearch={showSearch}
        loading={loading}
        value={selectValue}
        placeholder={placeHolder}
        disabled={disabled}
        onChange={(value) => {
          if (updateField !== undefined) {
            updateField(name, value);
          }
        }}
        filterOption={
          showSearch
            ? (input, option: any) => {
                if (!option) return false;
                
                if (searchInNodes) {
                  const searchText = getSearchText(option);
                  return searchText.toLowerCase().includes(input.toLowerCase());
                } else {
                  if (option.options !== undefined) {
                    return option.options.some((item: any) => 
                      item.children.toLowerCase().includes(input.toLowerCase())
                    );
                  } else {
                    return (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }
                }
              }
            : undefined
        }
        dropdownStyle={{
          maxWidth: 'none',  // Remove max-width restriction
        }}
      >
        {selectOptions?.map((item: any, index: number) => (
          <Option
            value={
              optionHasValue
                ? item
                : alternativeValue
                ? item[alternativeValue]
                : item.id
            }
            key={`${name}-${index}`}
            searchText={item.searchText}
          >
            {selectOptionLabel !== undefined ? item[selectOptionLabel] : item}
          </Option>
        ))}
      </Select>
    </div>
  );
};

Inputselect.defaultProps = {
  loading: false,
  showSearch: false,
  optionHasValue: false,
  disabled: false,
  selectOptions: [],
  isMultiple: false,
  searchInNodes: false,
};

export default Inputselect;