import React, { useState } from "react";
import { Form, Button, message } from "antd";
import { getLoanHistory } from "../../../api/wallet/wallets";
import { openNotificationWithIcon } from "../../../utils/helper";
import Inputdate from "../../../components/inputdate/inputdate";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const Loanstatement = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    from: "",
    to: "",
  });

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    if (name === "from") {
      form.setFieldValue("to", undefined);
    }
  };

  const onFinish = async (values: any) => {
    const { from, to } = values;
    setLoading(true);
    try {
      const res = await getLoanHistory({
        from,
        to,
      });

      const { status } = res.data;

      if (status === "success") {
        navigate("/dashboard/loan-statement-success");
      } else {
        openNotificationWithIcon(
          "warning",
          "Request statement",
          res.data.message
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Request statement");
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return (
      current < dayjs(new Date(form.getFieldValue("from"))).startOf("day") ||
      current > dayjs().endOf("day")
    );
  };

  const disabledDateFrom: RangePickerProps["disabledDate"] = (current) => {
    return current > dayjs().endOf("day");
  };

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-16" />
      </div>

      <div className="w-11/12 mx-auto  lg:w-3/12">
        <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
          Account Statement
        </h1>

        <p className="text-sm font-normal font-poppins mb-14 text-appcolorgrey ">
          Select period
        </p>

        <Form
          className="w-full"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            validateStatus={errors?.from.length > 0 ? "error" : undefined}
            help={errors?.from.length > 0 ? errors.from : undefined}
            label="From Date"
            name="from"
            rules={[
              {
                required: true,
                message: "From date is required",
              },
            ]}
          >
            <Inputdate
              name="from"
              updateField={updateField}
              placeHolder="Select From Date"
              format="YYYY-MM-DD"
              disabledDate={disabledDateFrom}
            />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.to.length > 0 ? "error" : undefined}
            help={errors?.to.length > 0 ? errors.from : undefined}
            label="To Date"
            name="to"
            rules={[
              {
                required: true,
                message: "To Date is required",
              },
            ]}
          >
            <Inputdate
              name="to"
              updateField={updateField}
              placeHolder="Select To Date"
              format="YYYY-MM-DD"
              disabledDate={
                form.getFieldValue("from") === undefined
                  ? disabledDateFrom
                  : disabledDate
              }
              dateValue={form.getFieldValue("to")}
            />
          </Form.Item>

          <Form.Item>
            <div className="">
              <Button
                htmlType="submit"
                className="mb-2"
                loading={loading}
                disabled={loading}
              >
                Request Statement
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Loanstatement;
