import React, { useEffect, useState } from "react";
import { repayLoanAmountInterface } from "./repayloadamount-interface";
import { Button, Form, message, Modal } from "antd";
import Inputamount from "../inputamount/inputamount";
import { openNotificationWithIcon } from "../../utils/helper";
import { payLoan } from "../../api/wallet/wallets";
import { useNavigate } from "react-router-dom";
import Alert from "antd/es/alert/Alert";

const Repayloanamount = ({
  visible,
  closeModal,
  type,
  amount,
  reference_code,
  setMethodValue,
}: repayLoanAmountInterface) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    amount: "",
  });

  const onFinish = async (values: any) => {
    const { amount } = values;

    setLoading(true);

    try {
      const res = await payLoan({
        amount,
        type,
        reference_code: reference_code ?? "",
      });

      const { status } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Repay loan", res.data.message);
        if (type === "full") {
          navigate("/dashboard/repayment-success");
        } else {
          navigate("/dashboard/partialrepayment-success");
        }
      } else {
        openNotificationWithIcon("warning", "Repay loan", res.data.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Repay loan");
      setMethodValue("");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    errorInfo.errorFields.forEach((item: any) => {
      setErrors((prevState) => ({
        ...prevState,
        [item.name[0]]: item.errors.length ? item.errors[0] : "",
      }));
    });
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (amount !== undefined) {
      updateField("amount", amount);
    }
  }, [amount]);

  return (
    <div>
      <Modal
        onCancel={loading ? undefined : closeModal}
        onOk={loading ? undefined : closeModal}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Repayment Amount
            </h1>
          </div>

          {type === "full" ? (
            <Alert
              className="my-4"
              type="info"
              message="You are about to make a full repayment"
            />
          ) : (
            <p className="mb-4 text-sm font-normal font-poppins text-appcolorgrey">
              KIndly enter your repayment amount
            </p>
          )}

          <div>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              initialValues={{
                amount: amount,
              }}
            >
              <Form.Item
                validateStatus={errors?.amount.length > 0 ? "error" : undefined}
                help={errors?.amount.length > 0 ? errors.amount : undefined}
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Amount is required",
                  },
                ]}
              >
                <Inputamount
                  name="amount"
                  updateField={updateField}
                  placeHolder="Enter amount"
                  currency="₦"
                  amountValue={form.getFieldValue("amount")}
                  disabled={type === "full"}
                />
              </Form.Item>

              <Form.Item>
                <div className="">
                  <Button
                    htmlType="submit"
                    className="mb-2"
                    disabled={loading}
                    loading={loading}
                  >
                    Confirm
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Repayloanamount;
