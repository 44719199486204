import React, { useEffect, useState } from "react";
import { AuthorizationinfoInterface } from "./banks-interface";
import { Alert, Button, Form, Input, Modal, message } from "antd";

const Authorizationinfo = ({
  visible,
  closeModal,
  info,
}: AuthorizationinfoInterface) => {
  return (
    <div>
      <Modal
        onCancel={() => {
          closeModal();
        }}
        onOk={() => {
          closeModal();
        }}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Authorize Bank
            </h1>{" "}
          </div>

          {info != null && (
            <div className="mb-2">
							<p className="mb-2 text-black font-poppins">Kindly follow the instruction below</p>
              <Alert message={<div>
								<p className="font-semibold text-black font-poppins">
									{info}
								</p>
							</div>} type="info" className="w-full" />
            </div>
          )}

          <Button
            htmlType="submit"
            className="mt-4 mb-2 "
            onClick={() => {
              closeModal();
            }}
          >
            Okay
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Authorizationinfo;
