import { http } from "..";
import {
	extendDirectLoanOfferPayload,
  getLoanBalancePayload,
  getLoanDetailsPayload,
  getLoanTypesPayload,
  loanOfferPayload,
} from "./loan-interface";

export const getLoanTypes = (data: getLoanTypesPayload) => {
  return http.post("/service/loan/types", data);
};

export const getLoanBalance = (data: getLoanBalancePayload) => {
  return http.post("/service/loan/balance", data);
};

export const loanOffer = (data: loanOfferPayload) => {
  return http.post("/service/loan/offers", data);
};

export const directLoanOffer = (data: loanOfferPayload) => {
  return http.post("/service/loan/direct-loan-request", data);
};

export const getLoanDetails = (data: getLoanDetailsPayload) => {
  return http.post("/service/loan/details", data);
};


export const extendDirectLoanOffer = (data: extendDirectLoanOfferPayload) => {
  return http.post("/service/loan/repayment-extension", data);
};