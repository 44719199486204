import { http } from "..";
import {
  addBankInterface,
  authorizeBankInterface,
  chargeBankPayload,
  deleteBankInterface,
  getBanksInterface,
  getSupportedBankBanksInterface,
} from "./banks-interface";

export const addBank = (data: addBankInterface) => {
  return http.post("/service/linkbankaccount/add", data);
};

export const getBanks = (data: getBanksInterface) => {
  return http.post("/service/linkbankaccount/details", data);
};

export const deleteBank = (data: deleteBankInterface) => {
  return http.post("/service/linkbankaccount/remove", data);
};

export const getSupportedBankBanks = (data: getSupportedBankBanksInterface) => {
  return http.post("/service/linkbankaccount/institutions", data);
};

export const authorizeBank = (data: authorizeBankInterface) => {
  return http.post("/service/linkbankaccount/authorization", data);
};

export const chargeBank = (data: chargeBankPayload) => {
  return http.post("/service/linkbankaccount/charge", data);
};
