import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const AddmoneySuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-16" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Successful
      </h1>

      <div className="flex justify-center mb-14">
        <img src="/img/vectors/topupsuccess.svg" alt="walletsuccess" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Your Wallet has been funded
      </h1>

      <div className="flex flex-col items-center justify-center w-9/12 mx-auto mb-8  lg:w-3/12">
        <Button
          className="mb-2"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Go To Dashboard
        </Button>
      </div>
    </div>
  );
};

export default AddmoneySuccess;
