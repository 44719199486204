import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { commaNumber } from "../../../utils/helper";
import moment from "moment";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { Alert, Button, message } from "antd";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { Options, usePDF } from "react-to-pdf";
import generatePDF, { Resolution, Margin } from "react-to-pdf";

const Viewtransactions = () => {
  const { reference_code } = useParams();
  const stateDetails = useLocation()?.state;
  const navigate = useNavigate();
  const item = stateDetails?.item;

  const [downloading, setDownloading] = useState(false);

  const options: Options = {
    filename: `${moment(item?.timestamp).format("L")}-${
      item?.reference_code
    }.pdf`,
    // default is `save`
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.LARGE,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };

  const getTargetElement = () => document.getElementById("content-id");

  const runDownload = () => {
    setDownloading(true);
    setTimeout(() => {
      generatePDF(getTargetElement, options);
      setDownloading(false);
    }, 200);
  };

  useEffect(() => {
    if (
      stateDetails === null ||
      stateDetails === undefined ||
      item === null ||
      item === undefined ||
      stateDetails?.item?.reference_code === undefined ||
      stateDetails?.item?.reference_code !== reference_code
    ) {
      navigate("/dashboard/transactions");
    }
  }, []);

  return (
    <div className="mt-[3rem]">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            navigate("/dashboard/transactions");
          }}
        >
          <ArrowLongLeftIcon className="w-5 h-5" />
          <p className="pl-1 font-poppins">Back</p>
        </div>

        <div
          className={
            downloading
              ? "flex flex-col justify-center"
              : "flex flex-col justify-center"
          }
          id="content-id"
        >
          <div className="flex justify-center ">
            <img src="/img/logo/newlogo.svg" alt="logo" className="w-24" />
          </div>

          <div className="flex items-baseline justify-between">
            <h1 className="mb-1 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
              Transaction Details
            </h1>
          </div>

          <div className="pt-3 pb-16">
            <div>
              <p
                className={`mb-0 text-2xl font-semibold font-ibmplexsans ${
                  item?.type === "Credit"
                    ? "text-appcolorgreen"
                    : "text-appcolorred"
                }`}
              >
                ₦ {commaNumber(parseFloat(item?.total_amount).toFixed(2))}
              </p>
              <div>
                <p className="font-bold">
                  {item?.type}{" "}
                  <span className={downloading ? "px-1" : ""}>at</span>{" "}
                  {moment(item?.timestamp).format("lll")}
                </p>
              </div>
            </div>

            <div className="mt-4 text-sm">
              {/* <p className="mb-4 text-sm font-poppins">Details</p> */}

              <div className="p-4 mb-3 rounded-lg shadow-lg bg-appcolorlightgray">
                {item?.attach?.name && (
                  <div className="flex justify-between mb-4 font-poppins">
                    <p className="w-[40%] font-semibold font-poppins"> Name</p>
                    <p className="w-[60%] flex justify-end font-poppins text-right">
                      {item?.attach?.name}
                    </p>
                  </div>
                )}

                {item?.attach?.name && (
                  <div className="flex justify-between mb-4 font-poppins">
                    <p className="w-[40%] font-semibold font-poppins"> Bank</p>
										<p className="w-[60%] flex justify-end font-poppins text-right">
                      {item?.attach?.bank}
                    </p>
                  </div>
                )}

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins"> Status</p>
									<p className="w-[60%] flex justify-end font-poppins text-right">
                    {item?.status}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins"> Type</p>
                  <p className="w-[60%] flex justify-end font-poppins text-right">
                    {item?.type}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">Date</p>
                  <p className="w-[60%] flex justify-end font-poppins text-right">
                    {moment(item?.timestamp).format("lll")}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">Remark</p>
                  <p className="w-[60%] flex justify-end font-poppins text-right">
                    {item?.remark ? item?.remark : "-"}
                  </p>
                </div>

                <div className="font-poppins">
                  <p className="font-semibold font-poppins">Reference</p>
                  {/* <div className="font-semibold">{item?.reference_code}</div> */}
                </div>

                <Alert
                  className="alert-plain"
                  // message={<p className="font-semibold">Reference</p>}
                  description={
                    <div>
                      <p>{item?.reference_code}</p>
                      {!downloading && (
                        <div className="mt-4 w-[fit-content]">
                          <CopyToClipboard
                            text={item?.reference_code}
                            onCopy={() => {
                              message.info(
                                "Reference code copied to clipboard"
                              );
                            }}
                          >
                            <div className="flex items-center p-1 px-2 text-sm bg-appcolorgray rounded-[0.5rem]">
                              <span className="pr-8">Copy</span>
                              <ClipboardDocumentIcon className="w-4 h-4 text-appcolorwhite" />
                            </div>
                          </CopyToClipboard>
                        </div>
                      )}
                    </div>
                  }
                  type="info"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pb-20 mb-20">
          <Button
            htmlType="submit"
            className="mt-4 mb-4"
            onClick={() => {
              runDownload();
            }}
            disabled={downloading}
            loading={downloading}
          >
            Download Receipt
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Viewtransactions;
