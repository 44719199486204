import { Alert, Button, Form, Input, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkAmount,
  commaNumber,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
  validatePhoneNumber,
} from "../../../utils/helper";
import {
  billPaymentRequest,
  getBillPaymentProviders,
  verifyBillPayment,
} from "../../../api/billpayment/billpayment";
import Inputselect from "../../../components/inputselect/inputselect";
import { getWalletsDetails } from "../../../api/wallet/wallets";
import { useDispatch, useSelector } from "react-redux";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import { RootState } from "../../../store/store";
import { debounce } from "lodash";
import { isValidPhoneNumber } from "libphonenumber-js";
import Inputamount from "../../../components/inputamount/inputamount";
import { useEnabledTransactionPin } from "../../../customhooks/useEnabledTransactionPin";
import TransactionPin from "../../../components/transactionpin/transactionpin";

const availableBillTypes = ["airtime", "internetdata", "electricity", "tv"];

const renewalOptions = ["Yes", "No"];

const getBillPaymentTitles = (value?: string) => {
  switch (value) {
    case availableBillTypes[0]:
      return "Airtime Purchase";
    case availableBillTypes[1]:
      return "Internet Data Purchase";
    case availableBillTypes[2]:
      return "Electricity Purchase";
    case availableBillTypes[3]:
      return "TV Package Purchase";
    default:
      return "Bill Payment";
  }
};

const Billpayment = () => {
  const { billType } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const hasPinEnabled = useEnabledTransactionPin();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({
    currency: "",
    phone: "",
    amount: "",
    number: "",
  });
  const [billPaymentProviders, setBillPaymentProviders] = useState([]);
  const [loadingBillPaymentProviders, setLoadingBillPaymentProviders] =
    useState(false);
  const [verifyingBillPayment, setVerifyingBillPayment] = useState(false);
  const [verifiedBillPaymentDetails, setVerifiedBillPaymentDetails] =
    useState<any>(null);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [packages, setPackages] = useState([]);
  const [currentPackageAmount, setCurrentPackageAmount] = useState(undefined);

  const [paymentSummaryDetails, setPaymentSummaryDetails] = useState<
    {
      title: string;
      value: any;
    }[]
  >([]);
  const [detailsToBeSubmitted, setDetailsToBeSubmitted] = useState<any | null>(
    null
  );
  const [showTransactionPin, setShowTransactionPin] = useState(false);

  const closeTransactionPin = () => {
    if (!loading) {
      setShowTransactionPin(false);
      setPaymentSummaryDetails([]);
      setDetailsToBeSubmitted(null);
    }
  };

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    reloadWallet: state.user.reloadWallet,
  }));

  const { user, reloadWallet } = state;

  const runVerifyBillPayment = async () => {
    setVerifyingBillPayment(true);
    setVerifiedBillPaymentDetails(null);

    if (
      form.getFieldValue("service_id") !== undefined &&
      (form.getFieldValue("phone") !== undefined ||
        form.getFieldValue("number") !== undefined)
    ) {
      try {
        const res = await verifyBillPayment(billType || "", {
          country_code: billType === availableBillTypes[3] ? undefined : "ng",
          service_id: form.getFieldValue("service_id") || "",
          phone: form.getFieldValue("phone"),
          number: form.getFieldValue("number"),
          type: form.getFieldValue("code"),
        });
        const { status, data } = res.data;

        if (status === "success") {
          if (data !== null && data !== undefined && data.length > 0) {
            setVerifiedBillPaymentDetails(data[0]);
          } else {
            setVerifiedBillPaymentDetails(null);
            setErrors((prevState) => ({
              ...prevState,
              phone: "Invalid phone number",
            }));
          }
        } else {
          message.warning(`Verify bill payment, ${res.data.message}`);
        }

        setVerifyingBillPayment(false);
      } catch (error) {
        setVerifyingBillPayment(false);
        message.error("Something went wrong: Verify bill payment");
      }
    } else {
      if (
        billType === availableBillTypes[0] ||
        billType === availableBillTypes[1]
      ) {
        // setErrors((prevState) => ({
        //   ...prevState,
        //   phone: "Invalid phone number",
        // }));
      }
      if (
        billType === availableBillTypes[2] ||
        billType === availableBillTypes[3]
      ) {
        // setErrors((prevState) => ({
        //   ...prevState,
        //   number: "Invalid unique ID",
        // }));
      }
      setVerifiedBillPaymentDetails(null);
      setVerifyingBillPayment(false);
    }
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    const serviceId = form.getFieldValue("service_id");
    const phoneNumber = form.getFieldValue("phone");
    const numberValue = form.getFieldValue("number");
    const codeValue = form.getFieldValue("code");

    if (name === "service_id") {
      if (
        billType === availableBillTypes[1] ||
        billType === availableBillTypes[2] ||
        billType === availableBillTypes[3]
      ) {
        form.setFieldValue("code", undefined);
        setCurrentPackageAmount(undefined);
        fetchBillPaymentProviders(true);
        form.setFieldValue("renewal", undefined);
        form.setFieldValue("number", undefined);
        setVerifiedBillPaymentDetails(null);

        setPaymentSummaryDetails([]);
        // setShowTransactionPin(false);
        setDetailsToBeSubmitted(null);
      }
    }

    if (name === "phone" || name === "service_id") {
      if (
        billType === availableBillTypes[0] ||
        billType === availableBillTypes[1]
      ) {
        if (phoneNumber !== undefined && phoneNumber.length === 11) {
          if (
            validatePhoneNumber(`+234${phoneNumber.substring(1)}`) &&
            serviceId !== undefined
          ) {
            runVerifyBillPayment();
          }
        } else {
          setErrors((prevState) => ({
            ...prevState,
            phone: phoneNumber === undefined ? "" : "Invalid phone number",
          }));
        }
      }
    }

    if (name === "code") {
      const filteredOptions: any[] = packages.filter(
        (item: any) => item?.code === value
      );
      setCurrentPackageAmount(filteredOptions[0]?.amount);
      if (billType === availableBillTypes[2]) {
        if (numberValue?.length === 11 && codeValue !== undefined) {
          runVerifyBillPayment();
        }
      }
    }

    if (name === "number" || name === "service_id") {
      if (billType === availableBillTypes[2]) {
        if (numberValue?.length === 11 && codeValue !== undefined) {
          runVerifyBillPayment();
        } else {
          setVerifiedBillPaymentDetails(null);
        }
      }

      if (billType === availableBillTypes[3]) {
        if (numberValue?.length === 10) {
          runVerifyBillPayment();
        } else {
          setVerifiedBillPaymentDetails(null);
        }
      }
    }
  };

  const fetchBillPaymentProviders = async (isPackages?: boolean) => {
    if (isPackages) {
      setLoadingPackages(true);
    } else {
      setLoadingBillPaymentProviders(true);
    }

    try {
      const res = await getBillPaymentProviders(billType || "", {
        country_code: "ng",
        service_id: isPackages ? form.getFieldValue("service_id") : undefined,
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null && data !== undefined) {
          if (isPackages) {
            setPackages(data?.[0]?.option);
          } else {
            setBillPaymentProviders(data);
          }
        } else {
          if (isPackages) {
            setPackages([]);
          } else {
            setBillPaymentProviders([]);
          }
        }
      } else {
        message.warning(
          `${
            isPackages
              ? "Get Bill payment packages"
              : "Get Bill payment providers"
          }, ${res.data.message}`
        );
      }

      if (isPackages) {
        setLoadingPackages(false);
      } else {
        setLoadingBillPaymentProviders(false);
      }
    } catch (error) {
      if (isPackages) {
        setLoadingPackages(false);
      } else {
        setLoadingBillPaymentProviders(false);
      }
      message.error("Something went wrong: Get Bill payment providers");
    }
  };

  const constructPaymentSummaryDetails = (values: {
    service_id: string;
    phone: any;
    amount: any;
    code: any;
    number: any;
    renewal: any;
  }) => {
    const serviceDetails: { provider: string; country_code: string }[] =
      billPaymentProviders.filter(
        (item: any) => item.service_id === values.service_id
      );

    const packageDetails: { code: string; type: string; amount: string }[] =
      packages.filter((item: any) => item.code === values.code);

    const detail: any = [
      {
        title: "Provider",
        value: serviceDetails[0]?.provider,
      },
    ];

    if (values.code === undefined) {
      if (billType !== availableBillTypes[0]) {
        detail.push({
          title: "Package",
          value: packageDetails[0]?.type ?? packageDetails[0]?.code,
        });

        detail.push({
          title: "Amount",
          value: `₦${commaNumber(packageDetails[0]?.amount)}`,
        });
      }
    } else {
      if (billType !== availableBillTypes[0]) {
        detail.push({
          title: "Package",
          value: packageDetails[0]?.type ?? packageDetails[0]?.code,
        });

        if (values.amount === undefined) {
          detail.push({
            title: "Amount",
            value: `₦${commaNumber(packageDetails[0]?.amount)}`,
          });
        }
      }
    }

    if (values.phone) {
      detail.push({
        title: "Phone",
        value: values.phone,
      });
    }

    if (values.amount) {
      detail.push({
        title: "Amount",
        value: `₦${commaNumber(values.amount)}`,
      });
    }

    if (values.number) {
      detail.push({
        title: "Unique ID",
        value: values.number,
      });
    }

    setPaymentSummaryDetails(detail);
  };

  const followThroughAfterPin = async (otp: string) => {
    try {
      setError(null);
      setLoading(true);

      const res = await billPaymentRequest(billType || "", {
        ...detailsToBeSubmitted,
        authorization_pin: otp,
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon(
          "success",
          `${getBillPaymentTitles(billType)}`,
          message
        );

        setPaymentSummaryDetails([]);
        setShowTransactionPin(false);
        setDetailsToBeSubmitted(null);

        navigate("/dashboard/bill-payment-success");
      } else {
        setError(message);
        openNotificationWithIcon(
          "error",
          `${getBillPaymentTitles(billType)}`,
          message
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(`Something went wrong: ${getBillPaymentTitles(billType)}`);
    }
  };

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    if (billType === availableBillTypes[0]) {
      const currentChosenServiceIdDetails: any[] = billPaymentProviders.filter(
        (item: any) => item?.service_id === values.service_id
      );

      newErrors["amount"] = checkAmount(
        values?.amount,
        currentChosenServiceIdDetails[0]?.option?.min_amount,
        currentChosenServiceIdDetails[0]?.option?.max_amount
      );
    }

    if (!validatePhoneNumber(`+234${values?.phone.substring(1)}`)) {
      newErrors["phone"] = "Invalid phone number";
    } else {
      newErrors["phone"] = "";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        let dataToSend = {
          service_id: values?.service_id,
          phone: values?.phone,
          amount:
            billType === availableBillTypes[3]
              ? currentPackageAmount
              : values?.amount,
          code: values?.code,
          number: values?.number,
          renewal: values?.renewal,
        };

        console.log(dataToSend);

        setDetailsToBeSubmitted(dataToSend);

        if (hasPinEnabled) {
          constructPaymentSummaryDetails(dataToSend);
          setLoading(false);
          setShowTransactionPin(true);
          return false;
        }

        const res = await billPaymentRequest(billType || "", {
          ...dataToSend,
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            `${getBillPaymentTitles(billType)}`,
            message
          );
          navigate("/dashboard/bill-payment-success");
        } else {
          setError(message);
          openNotificationWithIcon(
            "error",
            `${getBillPaymentTitles(billType)}`,
            message
          );
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          `Something went wrong: ${getBillPaymentTitles(billType)}`
        );
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (billType !== undefined && billType !== "") {
      if (availableBillTypes.includes(billType?.toLocaleLowerCase())) {
        fetchBillPaymentProviders();
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/dashboard");
    }
  }, [billType]);

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-center">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            {getBillPaymentTitles(billType)}
          </h1>
          {/* <div className="mb-4 ml-2">
            <Tag color="geekblue">{billType?.toLocaleUpperCase()}</Tag>
          </div> */}
        </div>

        {error != null && (
          <div className="mb-4">
            <Alert message={error} type="error" className="w-full" />
          </div>
        )}

        <div className="pt-4 pb-16">
          <Form
            className="w-full"
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            {/* <Form.Item
              validateStatus={errors?.currency.length > 0 ? "error" : undefined}
              help={errors?.currency.length > 0 ? errors.currency : undefined}
              label="Wallet"
              name="currency"
              rules={[
                {
                  required: true,
                  message: "Wallet is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Wallet"
                updateField={updateField}
                name={"currency"}
                selectOptions={wallets}
                loading={loadingWallets}
                selectOptionLabel="walletLabel"
                alternativeValue="currency"
              />
            </Form.Item> */}

            <Form.Item
              label="Biller"
              name="service_id"
              rules={[
                {
                  required: true,
                  message: "Biller is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Biller"
                updateField={updateField}
                name={"service_id"}
                selectOptions={billPaymentProviders}
                selectOptionLabel="provider"
                alternativeValue="service_id"
                loading={loadingBillPaymentProviders}
                showSearch={true}
              />
            </Form.Item>

            {billType === availableBillTypes[0] ? (
              <></>
            ) : (
              <>
                <Form.Item
                  label="Package/Bouquet"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Biller is required",
                    },
                  ]}
                >
                  <Inputselect
                    placeHolder="Select Package/Bouquet"
                    updateField={updateField}
                    name={"code"}
                    selectOptions={packages}
                    selectOptionLabel={
                      billType === availableBillTypes[1] ||
                      billType === availableBillTypes[3]
                        ? "type"
                        : billType === availableBillTypes[2]
                        ? "code"
                        : undefined
                    }
                    alternativeValue="code"
                    loading={loadingPackages}
                    selectValue={form.getFieldValue("code")}
                    showSearch={true}
                  />
                </Form.Item>
              </>
            )}

            {billType === availableBillTypes[3] && (
              <Form.Item
                label="Renewal"
                name="renewal"
                rules={[
                  {
                    required: true,
                    message: "Renewal is required",
                  },
                ]}
              >
                <Inputselect
                  placeHolder="Select Renewal"
                  updateField={updateField}
                  name={"renewal"}
                  selectOptions={renewalOptions}
                  optionHasValue={true}
                  selectValue={form.getFieldValue("renewal")}
                />
              </Form.Item>
            )}

            {billType === availableBillTypes[0] ||
            billType === availableBillTypes[1] ||
            billType === availableBillTypes[2] ||
            billType === availableBillTypes[3] ? (
              <>
                {billType === availableBillTypes[0] ||
                (billType === availableBillTypes[1] &&
                  currentPackageAmount !== undefined) ||
                billType === availableBillTypes[2] ||
                (billType === availableBillTypes[3] &&
                  currentPackageAmount !== undefined) ? (
                  <>
                    <Form.Item
                      validateStatus={
                        errors?.amount.length > 0 ? "error" : undefined
                      }
                      help={
                        errors?.amount.length > 0 ? errors.amount : undefined
                      }
                      label="Amount"
                      name="amount"
                      rules={[
                        {
                          required:
                            billType === availableBillTypes[1] ||
                            billType === availableBillTypes[3]
                              ? false
                              : true,
                          message: "Amount is required",
                        },
                      ]}
                    >
                      <Inputamount
                        name="amount"
                        updateField={updateField}
                        placeHolder="Enter amount"
                        currency="₦"
                        isWholeNumber={true}
                        amountValue={
                          billType === availableBillTypes[0]
                            ? undefined
                            : currentPackageAmount
                        }
                        disabled={
                          billType === availableBillTypes[1] ||
                          billType === availableBillTypes[3]
                        }
                      />
                    </Form.Item>
                  </>
                ) : (
                  <></>
                )}

                {billType === availableBillTypes[0] ||
                billType === availableBillTypes[1] ||
                billType === availableBillTypes[2] ||
                billType === availableBillTypes[3] ? (
                  <Form.Item
                    hasFeedback={
                      billType === availableBillTypes[2] ||
                      billType === availableBillTypes[3]
                        ? false
                        : verifiedBillPaymentDetails
                        ? true
                        : false
                    }
                    validateStatus={
                      verifyingBillPayment
                        ? "validating"
                        : (billType === availableBillTypes[2] ||
                            billType === availableBillTypes[3]) &&
                          errors?.phone.length > 0
                        ? "error"
                        : verifiedBillPaymentDetails
                        ? "success"
                        : errors?.phone.length > 0
                        ? "error"
                        : undefined
                    }
                    help={
                      verifyingBillPayment
                        ? "The information is being validated..."
                        : errors?.phone.length > 0
                        ? errors.phone
                        : undefined
                    }
                    label="Phone Number"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Phone Number is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="08000000000"
                      onChange={(e: any) => {
                        updateField("phone", e?.target?.value);
                      }}
                      maxLength={11}
                      type="number"
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}

                {billType === availableBillTypes[0] ||
                billType === availableBillTypes[1] ? (
                  <>
                    {verifiedBillPaymentDetails !== null && (
                      <>
                        {!verifiedBillPaymentDetails?.service?.provider
                          .toLowerCase()
                          .includes(
                            verifiedBillPaymentDetails?.id?.provider.toLowerCase()
                          ) ? (
                          <div className="mb-4">
                            <Alert
                              type="warning"
                              message={
                                <div>
                                  <b>Warning:</b>{" "}
                                  <span>
                                    You are about to purchase{" "}
                                    {billType === availableBillTypes[0]
                                      ? "airtime"
                                      : "internet data"}{" "}
                                    for a different provider other than{" "}
                                    {verifiedBillPaymentDetails?.service?.name.toUpperCase()}
                                  </span>
                                </div>
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {billType === availableBillTypes[2] ||
            billType === availableBillTypes[3] ? (
              <>
                <Form.Item
                  hasFeedback={verifiedBillPaymentDetails ? true : false}
                  validateStatus={
                    verifiedBillPaymentDetails
                      ? "success"
                      : errors?.number.length > 0
                      ? "error"
                      : undefined
                  }
                  help={errors?.number.length > 0 ? errors.number : undefined}
                  label="Customer Unique ID"
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: "Customer Unique ID is required",
                    },
                  ]}
                >
                  <Input
                    placeholder={
                      billType === availableBillTypes[2]
                        ? "Meter/Account Number"
                        : "Customer Unique ID"
                    }
                    onChange={(e: any) => {
                      updateField("number", e?.target?.value);
                    }}
                    maxLength={billType === availableBillTypes[2] ? 25 : 10}
                    max={billType === availableBillTypes[2] ? 25 : 10}
                  />
                </Form.Item>

                {verifiedBillPaymentDetails !== null && (
                  <div className="mb-4">
                    <Alert
                      type="info"
                      message={
                        <div className="font-poppins">
                          <p>
                            <b>Customer Name:</b>{" "}
                            <span>
                              {verifiedBillPaymentDetails?.customer_name}
                            </span>
                          </p>
                          {billType === availableBillTypes[3] ? (
                            <>
                              <p>
                                <b>Customer Number:</b>{" "}
                                <span>
                                  {verifiedBillPaymentDetails?.customer_number}
                                </span>
                              </p>
                              <p>
                                <b>Current Bouquet:</b>{" "}
                                <span>
                                  {verifiedBillPaymentDetails?.current_bouquet}
                                </span>
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                <b>Meter Number:</b>{" "}
                                <span>
                                  {verifiedBillPaymentDetails?.meter_number}
                                </span>
                              </p>
                              <p>
                                <b>Customer Address:</b>{" "}
                                <span>
                                  {verifiedBillPaymentDetails?.customer_address}
                                </span>
                              </p>
                            </>
                          )}
                        </div>
                      }
                    />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}

            <Form.Item>
              <Button
                htmlType="submit"
                className={`mb-2 ${verifyingBillPayment ? "mt-2" : ""}`}
                disabled={
                  loading || loadingBillPaymentProviders || verifyingBillPayment
                }
                loading={loading}
              >
                Make Payment
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {showTransactionPin && (
        <TransactionPin
          paymentType={getBillPaymentTitles(billType)}
          visible={showTransactionPin}
          closeModal={closeTransactionPin}
          loading={loading}
          paymentDetails={paymentSummaryDetails}
          onSubmit={followThroughAfterPin}
        />
      )}
    </div>
  );
};

export default Billpayment;
