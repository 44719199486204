import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  loginUserInterface,
  updateReloadWalletInterface,
  updateSubscribedInterface,
  updateUserInterface,
  UserState,
} from "./userSlice-interface";
import { setAuthToken } from "../../api";

const initialState: UserState = {
  user: null,
  token: null,
  isAuthenticated: false,
  reloadWallet: false,
  subscribed: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<loginUserInterface>) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.token = action.payload.token;
    },
    logoutUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
      setAuthToken(null);
    },
    updateUser: (state, action: PayloadAction<updateUserInterface>) => {
      state.user = action.payload.user;
    },
    updateReloadWallet: (
      state,
      action: PayloadAction<updateReloadWalletInterface>
    ) => {
      state.reloadWallet = action.payload.reloadWallet;
    },
    updateSubscribed: (
      state,
      action: PayloadAction<updateSubscribedInterface>
    ) => {
      state.subscribed = action.payload.subscribed;
    },
  },
});

export const {
  loginUser,
  logoutUser,
  updateUser,
  updateReloadWallet,
  updateSubscribed,
} = userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
