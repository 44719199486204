import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWalletsDetails } from "../../../api/wallet/wallets";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import { RootState } from "../../../store/store";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { DocumentIcon } from "@heroicons/react/24/outline";
import Walletcard from "../../../components/walletcard/walletcard";

const Wallets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [wallets, setWallets] = useState<any[]>([]);
  const [loadingWallets, setLoadingWallets] = useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    reloadWallet: state.user.reloadWallet,
  }));

  const { user, reloadWallet } = state;

  const getWallet = async () => {
    setLoadingWallets(true);
    try {
      const res = await getWalletsDetails();

      const { status, data } = res.data;

      if (status === "success") {
        let newWallets: any = [...wallets];
        newWallets.push({
          ...data,
        });

        setWallets(data);

        dispatch(
          updateUser({
            user: { ...user, wallets: data },
          })
        );

        dispatch(
          updateReloadWallet({
            reloadWallet: false,
          })
        );
      } else {
        message.warning(`Get wallets, ${res.data.message}`);
      }

      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      message.error("Something went wrong: Get wallets");
    }
  };

  useEffect(() => {
    getWallet();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reloadWallet) {
      getWallet();
    }

    // eslint-disable-next-line
  }, [reloadWallet]);

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto  lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Wallets
          </h1>
        </div>

        <div className="pb-16">
          {loadingWallets ? (
            <>
              {[1, 2, 3].map((item) => (
                <div className="mb-4" key={`trans-preloader-${item}`}>
                  <Skeletonloader height={"6rem"} />
                </div>
              ))}
            </>
          ) : (
            <>
              {wallets.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-2">
                  <div className="w-11 h-11">
                    <DocumentIcon className="cursor-pointer" />
                  </div>
                  <p className="text-center cursor-pointer font-poppins">
                    No wallet{" "}
                  </p>
                  <p className="text-center cursor-pointer font-poppins">
                    created.
                  </p>
                </div>
              ) : (
                <div>
                  {wallets.map((item, index) => (
                    <Walletcard
                      key={`wallets-card-${index}-${item.id}`}
                      availableBalance={parseFloat(
                        item.available_balance
                      ).toFixed(2)}
                      name={`${user?.firstname}  ${user?.lastname}`}
                      currency={item?.currency}
                      // updateViewWalletDetails={updateViewWalletDetails}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Wallets;
