import React from "react";
import { inputAmountInterface } from "./inputamount-interface";
import { Input } from "antd";
import { NumericFormat } from "react-number-format";

const Inputamount = ({
  hasError,
  placeHolder,
  currency,
  updateField,
  name,
  amountValue,
  isPercentage,
  disabled,
  isWholeNumber,
}: inputAmountInterface) => {
  return (
    <div className="flex items-center custom-number-input-holder">
      {currency && (
        <p className="pr-2 mb-0 text-base font-black currency-type">
          {currency}
        </p>
      )}

      <NumericFormat
        disabled={disabled}
        type="text"
        thousandsGroupStyle="thousand"
        thousandSeparator=","
        customInput={Input}
        value={amountValue}
        placeholder={placeHolder}
        className="custom-number-input"
        decimalScale={isPercentage ? undefined : isWholeNumber ? 0 : 2}
        fixedDecimalScale={true}
        allowNegative={false}
        onValueChange={(values, sourceInfo) => {
          if (values?.value !== undefined) {
            updateField(name, values.value);
          }
        }}
      />
    </div>
  );
};

Inputamount.defaultProps = {
  hasError: false,
  isPercentage: false,
  currency: undefined,
  disabled: false,
  isWholeNumber: false,
};

export default Inputamount;
