import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  createWallet,
  getSupportedCurrencies,
} from "../../../api/wallet/wallets";
import { openNotificationWithIcon } from "../../../utils/helper";
import Createwalletmodal from "./createwalletmodal";

const Createwallet = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [supportedCurrencies, setSupportedCurrencies] = useState<
    { currency: string }[]
  >([]);

  const closeModal = () => {
    setShowCreateWallet(false);
  };

  // const setupWallet = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await createWallet();

  //     const { status, message } = res.data;

  //     if (status === "success") {
  //       openNotificationWithIcon("success", "Create Wallet", message);
  //       navigate("/dashboard/wallet-success");
  //     } else {
  //       openNotificationWithIcon("error", "Create Wallet", message);
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     message.error("Something went wrong: Create wallets");
  //   }
  // };

  const fetchAvailableCurrencies = async () => {
    setLoading(true);
    try {
      const res = await getSupportedCurrencies();

      const { status, message, data } = res.data;

      if (status === "success") {
        setSupportedCurrencies(data);
      } else {
        openNotificationWithIcon("error", "Supported currencies", message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong:  Supported currencies");
    }
  };

  useEffect(() => {
    fetchAvailableCurrencies();
  }, []);

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-16" />
      </div>

      <div className="flex justify-center mb-16">
        <img src="/img/vectors/wallet.svg" alt="nowallet" className="w-24" />
      </div>

      <h1 className="mb-2 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Create Wallet
      </h1>

      <p className="w-11/12 mx-auto text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey  lg:w-3/12">
        Please note that you can also request for your virtual card and use your
        card for transactions on various channels.
      </p>

      <div className="flex flex-col items-center justify-center w-9/12 mx-auto mb-8  lg:w-3/12">
        <Button
          className="mb-2"
          onClick={() => {
            // setupWallet();
            setShowCreateWallet(true);
          }}
          // disabled={loading}
          // loading={loading}
        >
          Create wallet
        </Button>
      </div>

      {showCreateWallet && (
        <Createwalletmodal
          visible={showCreateWallet}
          closeModal={closeModal}
          supportedCurrencies={supportedCurrencies}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Createwallet;
