import { Upload, message } from "antd";
import React, { useState } from "react";
import { uploadRequestInfoProps } from "./requestloan-interface";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import {
  capitalizeTextChar,
  openNotificationWithIcon,
} from "../../../utils/helper";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const majorFileTypes = [
  {
    mainType: "image",
    accepting: ["image/jpeg", "image/png"],
    errorText: "JPG/PNG",
    accepts: ".png, .jpg, .jpeg",
  },
  {
    mainType: "pdf",
    accepting: ["application/pdf"],
    errorText: "PDF",
    accepts: ".pdf",
  },
  {
    mainType: "audio",
    accepting: ["audio/mpeg", "audio/mpeg3", "video/mpeg"],
    errorText: "audio",
    accepts: ".mp3",
  },
  {
    mainType: "video",
    accepting: ["video/mp4", "application/mp4"],
    errorText: "video",
    accepts: ".mp4",
  },
];

const UploadRequestInfo = ({
  token,
  submitting,
  type,
  name,
  updateField,
  uploadName,
  setUploading,
}: uploadRequestInfoProps) => {
  const [url, setUrl] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const getMimeTypeDetails = (value: string) => {
    const filteredMimeType = majorFileTypes.filter(
      (item) => item.mainType === value
    );
    return filteredMimeType[0];
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = getMimeTypeDetails(type)?.accepting.includes(
      file.type.toLowerCase()
    );
    if (!isJpgOrPng) {
      message.error(
        `You can only upload ${getMimeTypeDetails(type)?.errorText} file!`
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(
        `${capitalizeTextChar(
          getMimeTypeDetails(type)?.mainType
        )} must smaller than 5MB!`
      );
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0]?.status === "uploading") {
      setLoading(true);
      setUploading(true);
      setImageUrl("");
      setUrl("");
    }
    if (newFileList[0]?.status === "done") {
      setLoading(false);
      setUploading(false);
      if (newFileList[0]?.response.status === "success") {
        message.success(
          `${capitalizeTextChar(
            getMimeTypeDetails(type)?.mainType
          )} uploaded successfully`
        );
        setUrl(newFileList[0]?.response?.data);
        updateField(name, newFileList[0]?.response?.data);
      } else {
        updateField(name, undefined);
        openNotificationWithIcon(
          "error",
          `Upload ${capitalizeTextChar(getMimeTypeDetails(type)?.mainType)}`,
          newFileList[0]?.response?.message
        );
      }

      // Get this url from response in real world.
      getBase64(newFileList[0]?.originFileObj as RcFile, (url) => {
        setLoading(false);
        setUploading(false);
        setImageUrl(url);
      });
    }
    if (newFileList[0]?.status === "error") {
      updateField(name, undefined);
      setLoading(false);
      setUploading(false);
      openNotificationWithIcon(
        "error",
        `Upload ${capitalizeTextChar(getMimeTypeDetails(type)?.mainType)}`,
        `${capitalizeTextChar(
          getMimeTypeDetails(type)?.mainType
        )} could not be uploaded`
      );
    }
  };

  return (
    <div>
      <Upload
        fileList={type === "image" ? undefined : fileList}
        disabled={submitting}
        maxCount={1}
        accept={getMimeTypeDetails(type)?.accepts ?? majorFileTypes[0].accepts}
        beforeUpload={beforeUpload}
        name="file"
        listType={type === "image" ? "picture-card" : "picture"}
        className={`${type === "image" ? "" : "upload-others"}  ${
          type !== "image" && fileList.length > 0 ? "already-uploaded" : ""
        }`}
        onChange={handleChange}
        action={`${REACT_APP_BASE_URL}/misc/file-upload`}
        headers={{
          Authorization: `Bearer ${token}`,
        }}
        showUploadList={type === "image" ? false : true}
      >
        {type === "image" ? (
          <>
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </>
        ) : (
          <>{fileList.length === 0 && <>{uploadButton}</>}</>
        )}
      </Upload>
    </div>
  );
};

export default UploadRequestInfo;
