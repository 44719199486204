import React from 'react'
import { inputdateInterface } from './inputdate-interface'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'

const Inputdate = ({ hasError, placeHolder, updateField, name, disabledDate, format, dateValue }: inputdateInterface) => {
  return (
    <div className='rounded-lg w-ful bg-appcolorshade input-select'>
      <DatePicker
        bordered={false}
        className="w-full"
        placeholder={placeHolder}
        format={format}
        onChange={(date: any, dateString: string) => {
          updateField(name, dateString)
        }}
        disabledDate={disabledDate}
        value={dateValue === undefined ? undefined : dayjs(dateValue, format)}
      />
    </div>
  )
}

export default Inputdate