import Modal from "antd/es/modal/Modal";
import React from "react";
import { viewaccountProps } from "./viewaccount-interface";

const Viewaccount = ({
  visible,
  closeModal,
  accountDetails,
}: viewaccountProps) => {
  return (
    <div>
      <Modal
        onCancel={closeModal}
        onOk={closeModal}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div className="mb-4">
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Account Details
            </h1>

            <p>Here is your Kiowope Account </p>
          </div>

          <p className="mb-3">
            <b>Account Name</b>:{" "}
            {accountDetails?.account_name || "Not Available"}
          </p>

          <p className="mb-3">
            <b>Bank Name</b>: {accountDetails?.bank_name || "Not Available"}
          </p>

          <p className="mb-3">
            <b>Account Number</b>:{" "}
            {accountDetails?.account_number || "Not Available"}
          </p>

          <p className="p-4 mt-4 font-bold border rounded-md border-appcolordeepblue bg-appcolorlightgray text-appcolorred">
            <i>
              Kindly note that when funding your wallet, there is a processing
              fee of 0.5% capped at ₦1500.
            </i>
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Viewaccount;
