import { http } from "..";
import { getSavingsBalancePayload, getSavingsDetailsPayload, getSavingsOffersPayload, getSavingsTypesPayload, submitSavingsPaymentRequestPayload } from "./savings-interface";

export const getSavingsType= (data: getSavingsTypesPayload) => {
  return http.post("/service/savings/types", data);
};

export const getSavingsOffers = (data: getSavingsOffersPayload) => {
  return http.post("/service/savings/offers", data);
};

export const submitSavingsRequest = (data: getSavingsOffersPayload) => {
  return http.post("/service/savings/request", data);
};

export const submitSavingsPaymentRequest = (data: submitSavingsPaymentRequestPayload) => {
  return http.post("/service/savings/payment", data);
};


export const getSavingsBalance = (data: getSavingsBalancePayload) => {
  return http.post("/service/savings/balance", data);
};

export const getSavingsDetails = (data: getSavingsDetailsPayload) => {
  return http.post("/service/savings/details", data);
};



export const getInvestmentType= (data: getSavingsTypesPayload) => {
  return http.post("/service/investment/types", data);
};

export const getInvestmentOffers = (data: getSavingsOffersPayload) => {
  return http.post("/service/investment/offers", data);
};

export const submitInvestmentRequest = (data: getSavingsOffersPayload) => {
  return http.post("/service/investment/request", data);
};

export const getInvestmentBalance = (data: getSavingsBalancePayload) => {
  return http.post("/service/investment/balance", data);
};

export const getInvestmentDetails = (data: getSavingsDetailsPayload) => {
  return http.post("/service/investment/details", data);
};