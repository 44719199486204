import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

interface SecurityItem {
  type: string;
  value: string;
}

interface User {
  security?: SecurityItem[];
}

export const useEnabledTransactionPin = () => {
  const { user } = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const [hasPinEnabled, setHasPinEnabled] = useState(false);

  useEffect(() => {
    if (user?.security?.length > 0) {
      const checkForEnabled = user.security.filter(
        (item: SecurityItem) =>
          item.type === "authorization_enabled" && item.value === "Yes"
      );
      setHasPinEnabled(checkForEnabled.length > 0);
    }
  }, [user]);

  return hasPinEnabled;
};
