import React, { useEffect, useMemo, useState } from "react";
import { accountVerification } from "./accounts-interface";
import { Alert, Button, Form, Input, Upload, message } from "antd";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import { restructureLabel } from "./accountverification";
import Inputselect from "../../../components/inputselect/inputselect";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import { openNotificationWithIcon, updateCommonItems } from "../../../utils/helper";
import { updateUser } from "../../../store/userSlice/userSlice";
import {
  handleCACRegistrationVerification,
  handleNextofKinVerification,
} from "../../../api/base/base";

const Verifycac = ({
  submitting,
  setSubmitting,
  verificationType,
  setVerificationTodo,
  verificationTodo,
}: accountVerification) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [chosenCAC, setChosenCAC] = useState("");
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({
    chosenCAC: "",
  });

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onFinish = async (values: any) => {
    setError(null);
    setSubmitting(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await handleCACRegistrationVerification({
          option: values?.chosenCAC,
          number: values?.number,
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            `Verify ${restructureLabel(verificationType)}`,
            message
          );

          dispatch(
            updateUser({
              user: data,
            })
          );

          let newVerificationTodo: any[] = [];

          newVerificationTodo = updateCommonItems(
            verificationTodo,
            data?.kyc?.details
          );

          setVerificationTodo(newVerificationTodo);
        } else {
          setError(message);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error(
          `Something went wrong: Verify ${restructureLabel(verificationType)}`
        );
      }
    } else {
      setSubmitting(false);
    }
  };

  const availableOptions = useMemo(() => {
    const getVerificationOption = verificationTodo?.filter(
      (item) => item.parameter === verificationType
    )[0].option;
    const refixOptions: any[] = [];
    getVerificationOption.forEach((item: string) => {
      refixOptions.push(item);
    });
    return refixOptions;
  }, [verificationTodo]);

  return (
    <div>
      {error != null && (
        <div className="mb-2">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item
          validateStatus={errors?.chosenCAC.length > 0 ? "error" : undefined}
          help={errors?.chosenCAC.length > 0 ? errors.chosenCAC : undefined}
          label="Registration Type"
          name="chosenCAC"
          rules={[
            {
              required: true,
              message: "Registration type is required",
            },
          ]}
        >
          <Inputselect
            placeHolder="Choose Registration Type"
            updateField={updateField}
            name={"chosenCAC"}
            selectOptions={availableOptions}
            optionHasValue={true}
          />
        </Form.Item>

        <Form.Item
          label="Registration Number"
          name="number"
          rules={[
            {
              required: true,
              message: "Registration Number is required",
            },
          ]}
        >
          <Input placeholder="Registration Number" />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            className="mb-2 "
            disabled={submitting}
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Verifycac;
