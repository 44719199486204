import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store/store";
import { getVerificationTrack } from "../../../api/base/base";
import { Alert, Collapse, Form, Input, Tabs, TabsProps, message } from "antd";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";

import VerifyBvn from "./verifybvn";
import Verifybvnnumber from "./verifybvnnumber";
import Verifyidentitydocument from "./verifyidentitydocument";
import Verifynextofkin from "./verifynextofkin";
import Verifyaddress from "./verifyaddress";
import Verifysignature from "./verifysignature";
import Verifybusinessdocument from "./verifybusinessdocument";
import Verifycac from "./verifycac";
import VerifyNIN from "./verifynin";

const { Panel } = Collapse;

export const restructureLabel = (label: string) => {
  switch (label) {
    case "nin":
      return "NIN";
    case "bvn":
      return "BVN";
    case "bvn_phone_number":
      return "BVN Phone Number";
    case "user_identity_document":
      return "Identity Document";
    case "primary_next_of_kin":
      return "Primary Next Of Kin";
    case "secondary_next_of_kin":
      return "Secondary Next Of Kin";
    case "primary_guarantor":
      return "Primary Guarantor";
    case "secondary_guarantor":
      return "Secondary Guarantor";
    case "residential_address":
      return "Residential Address";
    case "signature":
      return "Signature";
    case "business_documents":
      return "Business Documents";
    case "business_address":
      return "Business Address";
    case "cac_registration":
      return "CAC Registration";
    default:
      return label;
  }
};

const Accountverification = () => {
  const { accountType } = useParams();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<undefined | string>(
    undefined
  );
  const [loadingTracks, setLoadingTracks] = useState(false);
  const [verificationTodo, setVerificationTodo] = useState<
    {
      parameter: string;
      status: "Pending" | "Processing" | "Successful";
      class: string;
      option: string[];
    }[]
  >([]);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const checkAccountTypeTracks = async () => {
    setLoadingTracks(true);
    try {
      const res = await getVerificationTrack({
        type: accountType || "",
      });

      const { status, data } = res.data;

      if (status === "success") {
        setVerificationTodo(data);
        setActiveTabKey(data[0].parameter);
      } else {
        message.warning(`Get tracks, ${res.data.message}`);
      }

      setLoadingTracks(false);
    } catch (error) {
      setLoadingTracks(false);
      message.error("Something went wrong: Get tracks");
    }
  };

  useEffect(() => {
    if (user && accountType) {
      if (
        user?.kyc?.available_account_type.some(
          (item: string) =>
            item.toLowerCase() === accountType.toLocaleLowerCase()
        )
      ) {
        checkAccountTypeTracks();
      } else {
        navigate("/dashboard/accounts/account-status");
      }
    } else {
      navigate("/dashboard/accounts/account-status");
    }
  }, [accountType]);

  const onChange = (key: string) => {
    setActiveTabKey(key);
  };

  const getActualVerificationForm = (
    parameter: string,
    status: string
  ): React.ReactNode => {
    switch (parameter) {
      case "nin":
        return status === "Pending" ? (
          <VerifyNIN
            verificationType="nin"
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      case "bvn":
        return status === "Pending" ? (
          <VerifyBvn
            verificationType="bvn"
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      case "bvn_phone_number":
        return status === "Pending" ? (
          <Verifybvnnumber
            verificationType="bvn_phone_number"
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      case "user_identity_document":
        return status === "Pending" ? (
          <Verifyidentitydocument
            verificationType="user_identity_document"
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      case "primary_next_of_kin":
      case "secondary_next_of_kin":
      case "primary_guarantor":
      case "secondary_guarantor":
        return status === "Pending" ? (
          <Verifynextofkin
            verificationType={parameter}
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      case "residential_address":
      case "business_address":
        return status === "Pending" ? (
          <Verifyaddress
            verificationType={parameter}
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      case "signature":
        return status === "Pending" ? (
          <Verifysignature
            verificationType="signature"
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      case "business_documents":
        return status === "Pending" ? (
          <Verifybusinessdocument
            verificationType="business_documents"
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      case "cac_registration":
        return status === "Pending" ? (
          <Verifycac
            verificationType="cac_registration"
            submitting={submitting}
            setSubmitting={setSubmitting}
            setVerificationTodo={setVerificationTodo}
            verificationTodo={verificationTodo}
          />
        ) : (
          <div className="">
            <Alert
              message={`${restructureLabel(parameter)} verification ${
                status === "Processing"
                  ? "submission is currently being processed."
                  : "is successfully done."
              }`}
              type={status === "Processing" ? "info" : "success"}
              className="w-full"
            />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  const transformVerificationTracks = useCallback(() => {
    const items: TabsProps["items"] = [];
    let previousPending = false;

    verificationTodo.forEach((item) => {
      const isDisabled = submitting || previousPending;

      items.push({
        // disabled: submitting,
        disabled: isDisabled,
        key: item?.parameter,
        label: restructureLabel(item?.parameter),
        children: getActualVerificationForm(item?.parameter, item?.status),
      });

      if (item?.status === "Pending") {
        previousPending = true;
      }
    });
    return items;
  }, [verificationTodo, submitting]);

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            <span className="pr-1 capitalize">{accountType}</span>
            Verification Tracks
          </h1>
        </div>

        <div>
          {loadingTracks ? (
            <Skeletonloader height={"10rem"} />
          ) : (
            <div>
              {/* <Tabs
                defaultActiveKey={activeTabKey}
                items={transformVerificationTracks()}
                onChange={onChange}
              /> */}
							<Collapse
                defaultActiveKey={[verificationTodo?.[0]?.parameter]}
                accordion
              >
                {transformVerificationTracks().map((item) => (
                  <Panel
                    header={<p className="font-semibold">{item?.label}</p>}
                    key={item?.key}
                    collapsible={item?.disabled ? "disabled" : undefined}
                  >
                    {item?.children}
                  </Panel>
                ))}
              </Collapse>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accountverification;
