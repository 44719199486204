import React, { useEffect, useState } from "react";
import { CreateabankInterface } from "./banks-interface";
import { Alert, Button, Form, Input, Modal, message } from "antd";
import Inputselect from "../../../components/inputselect/inputselect";
import { addBank, getSupportedBankBanks } from "../../../api/bank/bank";
import {
  openNotificationWithIcon,
  validateAccountNumber,
} from "../../../utils/helper";

const Createbank = ({ visible, closeModal }: CreateabankInterface) => {
  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [loadingSupportedBanks, setLoadingSupportedBanks] = useState(false);
  const [supportedBanks, setSupportedBanks] = useState([]);

  const [errors, setErrors] = useState({
    bank_code: "",
    account_no: "",
  });

  const updateField = (name: string, value: string): void => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onFinish = async (values: any) => {
    setError(null);
    setSubmitting(true);
    const newErrors = { ...errors };

    newErrors["account_no"] = validateAccountNumber(values?.account_no);

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await addBank({
          ...values,
          currency: "ngn",
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Link Bank", message);
          form.resetFields();
          closeModal(true);
        } else {
          setError(message);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error("Something went wrong: Link Bank");
      }
    } else {
      setSubmitting(false);
    }
  };

  const fetchAvailableBanks = async () => {
    setLoadingSupportedBanks(true);
    try {
      const res = await getSupportedBankBanks({
        currency: "ngn",
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        setSupportedBanks(data);
      } else {
        openNotificationWithIcon("error", "Supported Banks", message);
      }

      setLoadingSupportedBanks(false);
    } catch (error) {
      setLoadingSupportedBanks(false);
      message.error("Something went wrong:  Supported banks");
    }
  };

  useEffect(() => {
    fetchAvailableBanks();
  }, []);

  return (
    <div>
      <Modal
        onCancel={
          submitting
            ? undefined
            : () => {
                closeModal();
              }
        }
        onOk={
          submitting
            ? undefined
            : () => {
                closeModal();
              }
        }
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Link Bank
            </h1>{" "}
          </div>

          {error != null && (
            <div className="mb-2">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}
        </div>

        <Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
          initialValues={{
            currency: "NGN",
          }}
        >
          <Form.Item
            validateStatus={errors?.bank_code.length > 0 ? "error" : undefined}
            help={errors?.bank_code.length > 0 ? errors.bank_code : undefined}
            label="Bank"
            name="bank_code"
            rules={[
              {
                required: true,
                message: "Bank is required",
              },
            ]}
          >
            <Inputselect
              placeHolder="Choose Bank"
              updateField={updateField}
              name={"bank_code"}
              selectOptions={supportedBanks}
              optionHasValue={false}
              alternativeValue="bank_code"
              selectOptionLabel="institution"
              loading={loadingSupportedBanks}
              showSearch={true}
            />
          </Form.Item>

          <Form.Item
            label="Account Number"
            name="account_no"
            rules={[
              {
                required: true,
                message: "Account Number is required",
              },
            ]}
            validateStatus={errors?.account_no.length > 0 ? "error" : undefined}
            help={errors?.account_no.length > 0 ? errors.account_no : undefined}
          >
            <Input
              maxLength={10}
              type="account_no"
              placeholder=" Account Number"
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className="mb-2 "
              disabled={submitting}
              loading={submitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Createbank;
