import React, { useState, useEffect } from "react";
import { Form, Input, Button, Alert } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { checkReference, sendReference } from "../../api/base/base";
import {
  openNotificationWithIcon,
} from "../../utils/helper";
import Skeletonloader from "../../components/skeletonloader/skeletonloader";

const Validate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { reference } = useParams();

  const [parameter, setParameter] = useState("");
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [referenceData, setReferenceData] = useState(null)

  const validateRefence = async () => {
    setValidating(true);
    try {
      const res = await checkReference({
        reference_code: reference || "",
      });

      const { data, status, message } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Confirmation", message);
        setParameter(data.parameter);
      } else {
        openNotificationWithIcon("error", "Confirmation", message);
        navigate("/");
      }

      setValidating(false);
    } catch (error) {
      setValidating(false);
      openNotificationWithIcon("error", "Confirmation", "Something went wrong");
      navigate("/");
    }
  };

  useEffect(() => {
    validateRefence();
  }, []);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const res = await sendReference({
        reference_code: reference || "",
        value: values.bvn,
      });

      const { data, status, message } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Confirmation", message);
        navigate("/");
      } else {
        openNotificationWithIcon("error", "Confirmation", message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon("error", "Confirmation", "Something went wrong");
    }
  };

  return (
    <div className="mt-2">
      <div className="flex justify-center">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-16" />
      </div>

      <h1 className="text-2xl font-semibold text-center capitalize mb-9 font-ibmplexsans text-appcolorblue">
        {parameter} Confirmation Page
      </h1>

      {error != null && (
        <div className="flex justify-center w-11/12 mx-auto mb-4 lg:w-3/12">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <div className="flex flex-col justify-center w-11/12 mx-auto lg:w-3/12">
        {validating ? (
          <Skeletonloader height={"7rem"} />
        ) : (
          <Form
            className="w-full"
            form={form}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Enter BVN"
              name="bvn"
              rules={[
                {
                  required: true,
                  message: "BVN is required",
                },
              ]}
            >
              <Input placeholder="Enter BVN" maxLength={11} />
            </Form.Item>

            <Form.Item>
              <div className="flex flex-col items-center justify-center mx-auto my-8">
                <Button
                  htmlType="submit"
                  className="mb-2 "
                  disabled={loading}
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default Validate;
