import React, { useState } from "react";
import { accountVerification } from "./accounts-interface";
import { Button, Col, Form, Input, Row, message } from "antd";
import { formValuesInterface } from "../confirmotp/confirmotp-interface";
import { handleBVNPhoneNumberVerification } from "../../../api/base/base";
import {
  openNotificationWithIcon,
  updateCommonItems,
} from "../../../utils/helper";
import { updateUser } from "../../../store/userSlice/userSlice";
import { useDispatch } from "react-redux";

const Verifybvnnumber = ({
  submitting,
  setSubmitting,
  verificationType,
  setVerificationTodo,
  verificationTodo,
}: accountVerification) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [resendingPhone, setResendingPhone] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [verifyingPhone, setVerifyingPhone] = useState(false);
  const [errors, setErrors] = useState({
    emailOTP: "",
    phoneOTP: "",
  });

  const onFinishPhone = async (values: formValuesInterface) => {
    const { otp } = values;

    setLoading(false);
    setVerifyingPhone(true);
    setSubmitting(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await handleBVNPhoneNumberVerification({
          otp,
          mode: "verify_otp",
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Verify BVN phone", message);
          setPhoneVerified(true);

          dispatch(
            updateUser({
              user: data,
            })
          );

          let newVerificationTodo: any[] = [];

          newVerificationTodo = updateCommonItems(
            verificationTodo,
            data?.kyc?.details
          );

          setVerificationTodo(newVerificationTodo);
        } else {
          openNotificationWithIcon("error", "BVN phone", message);
        }

        setLoading(false);
        setVerifyingPhone(false);
        setSubmitting(false);
      } catch (error: any) {
        setLoading(false);
        setVerifyingPhone(false);
        setSubmitting(false);
        message.error("Something went wrong: Verify BVN phone");
      }
    } else {
      setLoading(false);
    }
  };

  const resendOTP = async (resendType: "email" | "phone") => {
    if (resendType === "phone") {
      setResendingPhone(true);
      try {
        const res = await handleBVNPhoneNumberVerification({
          mode: "send_otp",
        });

        const { status, message } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            "Resend Phone Verification",
            message
          );
        } else {
          openNotificationWithIcon(
            "error",
            "Resend Phone Verification",
            message
          );
        }

        setResendingPhone(false);
      } catch (error) {
        setResendingPhone(false);
        message.error("Something went wrong, Resend phone otp");
      }
    }
  };

  return (
    <div>
      <Form className="w-full" onFinish={onFinishPhone} layout="vertical">
        <Form.Item
          label={
            phoneVerified
              ? "BVN PHONE VERIFIED"
              : "Enter OTP sent to your BVN phone number"
          }
          name="otp"
          rules={[
            {
              required: true,
              message: "OTP is required",
            },
          ]}
          hasFeedback={phoneVerified || verifyingPhone}
          validateStatus={
            phoneVerified
              ? "success"
              : verifyingPhone
              ? "validating"
              : undefined
          }
        >
          <Input
            placeholder={phoneVerified ? "BVN PHONE VERIFIED" : "Enter OTP"}
            disabled={resendingPhone || phoneVerified || verifyingPhone}
            value={phoneVerified ? "PHONE VERIFIED" : undefined}
          />
        </Form.Item>
        {!phoneVerified && (
          <Form.Item>
            <Row justify="space-between">
              <Col>
                <Button
                  className="purple-skel"
                  size="small"
                  onClick={() => {
                    resendOTP("phone");
                  }}
                  loading={resendingPhone}
                  disabled={resendingPhone || phoneVerified || verifyingPhone}
                >
                  Request
                </Button>
              </Col>
              <Col>
                <Button
                  className="purple-skel"
                  size="small"
                  htmlType="submit"
                  loading={verifyingPhone}
                  disabled={resendingPhone || phoneVerified || verifyingPhone}
                >
                  Validate
                </Button>
              </Col>
            </Row>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

export default Verifybvnnumber;
