import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

const Errorviewscreen = () => {
  return (
    <div className="mt-2">
      <div className="w-11/12 h-screen mx-auto  lg:w-3/12">
        <div className="flex flex-col items-center justify-center h-full my-auto mt-2">
          <div className="w-11 h-11">
            <ExclamationCircleIcon className="cursor-pointer" />
          </div>
          <p className="text-center cursor-pointer font-poppins">
            App cannot be viewed
          </p>
          <p className="text-center cursor-pointer font-poppins">
            on this device
          </p>
        </div>
      </div>
    </div>
  );
};

export default Errorviewscreen;
