import { Input } from 'antd'
import React from 'react'
import { inputwithaddonInterface } from './inputwithaddon-interface'

const Inputwithaddon = ({ hasError, placeHolder, updateField, name, inputValue, addonAfter, addonBefore, disabled }: inputwithaddonInterface) => {
  return (
    <div className='w-full px-1 py-3 rounded-lg bg-appcolorshade'>
      <Input
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        bordered={false}
        value={inputValue}
        placeholder={placeHolder}
        onChange={(e) => {
          updateField(name, e.target.value)
        }}
        disabled={disabled}
        className="input-addon"
      />
    </div>
  )
}

export default Inputwithaddon