import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { skeletonloaderInterface } from './skeletonloader-interface';

const Skeletonloader = ({ height, width, borderRadius, marginRight }: skeletonloaderInterface) => {
  return (
    <div className="skeletonloader">
      <Skeleton
        style={{
          height: height,
          width: width,
          borderRadius: borderRadius,
          marginRight: marginRight
        }}
      />
    </div>
  );
};

Skeletonloader.defaultProps = {
  height: 16,
  width: '100%',
  borderRadius: 0,
  marginRight: 0
}

export default Skeletonloader;
