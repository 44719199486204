import { Button, Modal } from "antd";
import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export interface PrivacymodalInterface {
  visible: boolean;
  closeModal: () => void;
}

const Privacymodal = ({ visible, closeModal }: PrivacymodalInterface) => {
  return (
    <div>
      <Modal
        onCancel={closeModal}
        onOk={closeModal}
        open={visible}
        footer={null}
        destroyOnClose
        width={"100%"}
        // className="pdf-modal"
      >
        <div>
          <div className="mb-4">
            <Button>
              <a href={"/docs/Privacy.pdf"} download>
                Download Document
              </a>
            </Button>
            <DocViewer
              documents={[
                {
                  uri: "/docs/Privacy.pdf",
                },
              ]}
              activeDocument={{
                uri: "/docs/Privacy.pdf",
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Privacymodal;
