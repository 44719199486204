import React, { useEffect, useState } from "react";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import {
  Alert,
  Button,
  Form,
  Input,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import {
  openNotificationWithIcon,
  updateCommonItems,
  validatePhoneNumber,
} from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  handleBVNVerification,
  handleNINVerification,
} from "../../../api/base/base";
import { accountVerification } from "./accounts-interface";
import { updateUser } from "../../../store/userSlice/userSlice";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const VerifyNIN = ({
  submitting,
  setSubmitting,
  verificationType,
  setVerificationTodo,
  verificationTodo,
}: accountVerification) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const [url, setUrl] = useState("");
  const [errors, setErrors] = useState({
    number: "",
  });

  const state = useSelector((state: RootState) => ({
    token: state.user.token,
    user: state.user.user,
  }));

  const { token, user } = state;

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (url.length > 0) {
      updateField("url", url);
    }
  }, [url]);

  const onFinish = async (values: any) => {
    const { number } = values;
    setError(null);
    setSubmitting(true);
    const newErrors = { ...errors };

    if (number.length === 11) {
      newErrors["number"] = "";
    } else {
      newErrors["number"] = "Invalid phone number";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await handleNINVerification({
          number: values?.number,
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Verify NIN", message);

          dispatch(
            updateUser({
              user: data,
            })
          );

          // const checkFromResponse = data?.kyc?.details.filter(
          //   (item: any) => item?.parameter === verificationType
          // );

          // if (checkFromResponse.length > 0) {
          // const newVerificationTodo = [...verificationTodo];
          // const getIndex = newVerificationTodo.findIndex(
          //   (item: any) => item?.parameter === verificationType
          // );
          // newVerificationTodo[getIndex].status = checkFromResponse[0].status;
          // setVerificationTodo(newVerificationTodo);

          let newVerificationTodo: any[] = [];

          newVerificationTodo = updateCommonItems(
            verificationTodo,
            data?.kyc?.details
          );

          setVerificationTodo(newVerificationTodo);
          // }
        } else {
          setError(message);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error("Something went wrong: Verify NIN");
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <div>
      {error != null && (
        <div className="mb-2">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <Form
        className="w-full"
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="NIN"
          name="number"
          rules={[
            {
              required: true,
              message: "NIN is required",
            },
          ]}
        >
          <Input placeholder="NIN" max={11} />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            className="mb-2 "
            disabled={submitting}
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VerifyNIN;
