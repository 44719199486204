import React, { useEffect, useState } from "react";
import { generatestatementProps } from "./transactions-interface";
import { Button, Form, message, Modal } from "antd";
import Inputselect from "../../../components/inputselect/inputselect";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletsDetails,
  getWalletStatement,
} from "../../../api/wallet/wallets";
import {
  commaNumber,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
} from "../../../utils/helper";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import { RootState } from "../../../store/store";
import Inputdate from "../../../components/inputdate/inputdate";
import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";

const Generatestatement = ({ visible, closeModal }: generatestatementProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<any>(null);

  const [wallets, setWallets] = useState<any[]>([]);
  const [loadingWallets, setLoadingWallets] = useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const getWallet = async () => {
    setLoadingWallets(true);
    try {
      const res = await getWalletsDetails();

      const { status, data } = res.data;

      if (status === "success") {
        let newWallets: any = [];
        data.forEach((item: any) => {
          newWallets.push({
            ...item,
            walletLabel: `${item?.currency.toUpperCase()} WALLET - ${transformCurrencyToSymbol(
              item?.currency
            )} ${commaNumber(parseFloat(item?.available_balance).toFixed(2))}`,
          });
        });

        setWallets(newWallets);

        dispatch(
          updateUser({
            user: { ...user, wallets: data },
          })
        );

        dispatch(
          updateReloadWallet({
            reloadWallet: false,
          })
        );
      } else {
        message.warning(`Get wallets, ${res.data.message}`);
      }

      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      message.error("Something went wrong: Get wallets");
    }
  };

  useEffect(() => {
    getWallet();

    // eslint-disable-next-line
  }, []);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current > moment().endOf("day");
  };

  const onFinish = async (values: any) => {
    const { date_filter_from, date_filter_to } = values;

    let continueFilter = true;

    if (date_filter_from !== undefined && date_filter_to !== undefined) {
      if (moment(date_filter_from).isAfter(moment(date_filter_to))) {
        openNotificationWithIcon(
          "warning",
          "Filter Transaction",
          "Invalid date filter"
        );
        continueFilter = false;
      }
    }

    if (continueFilter) {
      try {
        setLoading(true);

        const res = await getWalletStatement({
          ...values,
        });

        const { status, data, blob } = res.data;
        if (status === "success") {
          openNotificationWithIcon(
            "success",
            "Generate Statement",
            `Account statement has been successfully sent to your email address`
          );
          closeModal();
        } else {
          message.warning(`Generate Statement, ${res.data.message}`);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        message.error("Something went wrong: Generate Statement");
      }
    }
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  return (
    <div>
      <Modal
        onCancel={loading ? undefined : closeModal}
        onOk={loading ? undefined : closeModal}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-xl font-semibold font-ibmplexsans text-appcolorblue">
              Generate Statement
            </h1>
          </div>

          <div className="mt-4">
            <Form layout="vertical" onFinish={onFinish} form={form}>
              <Form.Item
                label="Wallet"
                name="wallet_id"
                rules={[
                  {
                    required: true,
                    message: "Wallet is required",
                  },
                ]}
              >
                <Inputselect
                  placeHolder="Select Wallet"
                  updateField={(_, value) => {
                    setSelectedCurrency(value);
                    updateField("wallet_id", value);
                  }}
                  name={"wallet_id"}
                  selectOptions={wallets}
                  loading={loadingWallets}
                  selectOptionLabel="walletLabel"
                  alternativeValue="wallet_id"
                />
              </Form.Item>

              <Form.Item label="Date From" name="date_filter_from">
                <Inputdate
                  updateField={updateField}
                  name="date_filter_from"
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item label="Date To" name="date_filter_to">
                <Inputdate
                  updateField={updateField}
                  name="date_filter_to"
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  className="mb-2"
                  disabled={loading}
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Generatestatement;
