import React, { useEffect, useMemo, useState } from "react";
import { accountVerification } from "./accounts-interface";
import {
  Alert,
  Button,
  Form,
  Input,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  openNotificationWithIcon,
  updateCommonItems,
} from "../../../utils/helper";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import { RcFile } from "antd/es/upload";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  handleAddressVerification,
  handleIDVerification,
} from "../../../api/base/base";
import { updateUser } from "../../../store/userSlice/userSlice";
import { restructureLabel } from "./accountverification";
import { states } from "../../../utils/data";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const Verifyaddress = ({
  submitting,
  setSubmitting,
  verificationType,
  setVerificationTodo,
  verificationTodo,
}: accountVerification) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [cities, setCities] = useState<string[]>([]);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    verificationOption: "",
  });

  const state = useSelector((state: RootState) => ({
    token: state.user.token,
    user: state.user.user,
  }));

  const { token, user } = state;

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0].status === "uploading") {
      setLoading(true);
      setImageUrl("");
      setUrl("");
    }
    if (newFileList[0].status === "done") {
      setLoading(false);
      if (newFileList[0].response.status === "success") {
        message.success(
          `${restructureLabel(verificationType)} uploaded successfully`
        );
        setUrl(newFileList[0]?.response?.data);
      } else {
        openNotificationWithIcon(
          "error",
          `Upload ${restructureLabel(verificationType)}`,
          newFileList[0]?.response?.message
        );
      }

      // Get this url from response in real world.
      getBase64(newFileList[0].originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
    if (newFileList[0].status === "error") {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        `Upload ${restructureLabel(verificationType)}`,
        `${restructureLabel(verificationType)} could not be uploaded`
      );
    }
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    if (name === "state") {
      const getCities = states.filter((item) => item.state === value)[0].lgas;
      setCities(getCities);
      form.setFieldValue("city", undefined);
    }
  };

  useEffect(() => {
    if (url.length > 0) {
      updateField("url", url);
    }
  }, [url]);

  const onFinish = async (values: any) => {
    setError(null);
    setSubmitting(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await handleAddressVerification(
          {
            photo_url: values?.url,
            state: values?.state,
            city: values?.city,
            street: values?.street,
            postal_code: values?.postal_code,
          },
          verificationType.replace(/_/g, "-")
        );

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            `Verify ${restructureLabel(verificationType)}`,
            message
          );

          dispatch(
            updateUser({
              user: data,
            })
          );

          let newVerificationTodo: any[] = [];

          newVerificationTodo = updateCommonItems(
            verificationTodo,
            data?.kyc?.details
          );

          setVerificationTodo(newVerificationTodo);
        } else {
          setError(message);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error(
          `Something went wrong: Verify ${restructureLabel(verificationType)}`
        );
      }
    } else {
      setSubmitting(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="mb-20">
      {error != null && (
        <div className="mb-2">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <Form
        className="w-full"
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: "State is required",
            },
          ]}
        >
          {/* <Input placeholder="State" /> */}
          <Inputselect
            placeHolder="Select State"
            updateField={updateField}
            name={"state"}
            selectOptions={states}
            alternativeValue="state"
            selectOptionLabel="state"
            showSearch={true}
          />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "City is required",
            },
          ]}
        >
          {/* <Input placeholder="City" /> */}
          <Inputselect
            placeHolder="Select City"
            updateField={updateField}
            name={"city"}
            selectOptions={cities}
            optionHasValue={true}
            selectValue={form.getFieldValue("city")}
          />
        </Form.Item>

        <Form.Item
          label="Street"
          name="street"
          rules={[
            {
              required: true,
              message: "Street is required",
            },
          ]}
        >
          <Input placeholder="Street/Office address" />
        </Form.Item>

        <Form.Item
          label="Postal Code"
          name="postal_code"
          rules={
            [
              // {
              //   required: true,
              //   message: "Street is required",
              // },
            ]
          }
        >
          <Input placeholder="Postal code" />
        </Form.Item>

        <Form.Item
          name="url"
          rules={[
            {
              required: true,
              message: `${restructureLabel(verificationType)} is required`,
            },
          ]}
          label={<span>Upload {restructureLabel(verificationType)}</span>}
        >
          <Upload
            disabled={submitting}
            maxCount={1}
            accept={".png, .jpg, .jpeg"}
            beforeUpload={beforeUpload}
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            onChange={handleChange}
            action={`${REACT_APP_BASE_URL}/misc/file-upload`}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            showUploadList={false}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            className="mb-2 "
            disabled={submitting || loading}
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Verifyaddress;
