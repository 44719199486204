import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { Alert, Button, message, Pagination, PaginationProps, Tag } from "antd";
import {
  getPaymentProductsManager,
  getPaymentProductsOrders,
} from "../../../api/payment/payment";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { commaNumber } from "../../../utils/helper";
import Transactionsfilter from "../transactions/transactionsfilter";

const Orders = () => {
  const { product_id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);
  const [otherFilters, setOtherFilters] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [products, setProducts] = useState<
    {
      name: string;
      category: string;
    }[]
  >([]);
  const [loadingProducts, setLoadingProducts] = useState(false);

  const fetchProducts = useCallback(async () => {
    if (product_id !== undefined) {
      try {
        setLoadingProducts(true);
        const res = await getPaymentProductsManager({
          id: product_id ?? "",
          currency: "ngn",
        });
        const { status, data } = res.data;

        if (status === "success") {
          if (data !== null && data !== undefined) {
            setProducts(data);
            setTotal(parseInt(res.data.counts));
          } else {
            setProducts([]);
          }
        } else {
          message.warning(`Get products, ${res.data.message}`);
          navigate("/dashboard/business-tools");
        }

        setLoadingProducts(false);
      } catch (error) {
        setLoadingProducts(false);
        message.error("Something went wrong: Get products");
      }
    }
  }, [product_id]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const requestData = useMemo(() => {
    let realOtherFilters = {};
    if (otherFilters !== null) {
      realOtherFilters = otherFilters;
    }
    return {
      ...realOtherFilters,
      currency: "ngn",
      limit: otherFilters === null ? limit : 8,
      page: otherFilters === null ? currentPage : 1,
    };
  }, [limit, currentPage, otherFilters]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const fetchOrders = useCallback(async () => {
    setLoading(true);

    try {
      const res = await getPaymentProductsOrders({
        ...requestData,
        id: product_id || "",
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null && data !== undefined) {
          setOrders(data);
          setTotal(parseInt(res.data.counts));
        } else {
          setOrders([]);
        }
      } else {
        message.warning(`Get orders, ${res.data.message}`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Get orders");
    }
  }, [requestData]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const closeShowFilter = () => [setShowFilter(false)];

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
            Order Details
          </h1>
        </div>

        <Alert
          type="info"
          description={
            <div>
              <div className="flex items-center mb-1">
                <h1 className="mb-2 mr-2">Product Name: </h1>

                {loadingProducts ? (
                  <Skeletonloader height={"1.1rem"} width="7rem" />
                ) : (
                  <h1 className="mb-2 font-bold">{products[0]?.name} </h1>
                )}
              </div>

              <div className="flex items-start">
                <h1 className="mb-2 mr-2">Category: </h1>

                {loadingProducts ? (
                  <Skeletonloader height={"1.1rem"} width="10rem" />
                ) : (
                  <h1 className="mb-2">{products[0]?.category} </h1>
                )}
              </div>
            </div>
          }
        />
      </div>

      <div className="pt-4 pb-20 mb-20 px-[0.5rem]">
        {loading ? (
          <>
            {[1, 2, 3].map((item) => (
              <div className="mb-4" key={`trans-preloader-${item}`}>
                <Skeletonloader height={"7rem"} />
              </div>
            ))}
          </>
        ) : (
          <>
            {orders.length === 0 ? (
              <div className="flex flex-col items-center justify-center mt-2">
                <div className="w-11 h-11">
                  <DocumentIcon className="cursor-pointer" />
                </div>
                <p className="cursor-pointer font-poppins">No products found</p>

                <p className="text-center cursor-pointer font-poppins">
                  <span
                    className="text-appcolorblue"
                    onClick={() => {
                      setLimit(8);
                      setCurrentPage(1);
                      setOtherFilters(null);
                    }}
                  >
                    Refresh
                  </span>
                </p>
              </div>
            ) : (
              <>
                <div>
                  <div className="flex justify-end mb-4">
                    <div className="flex items-center">
                      <span
                        className="text-sm font-bold text-appcolorblue"
                        onClick={() => {
                          if (!loading) {
                            setShowFilter(true);
                          }
                        }}
                      >
                        Filter
                      </span>
                      {otherFilters !== null && (
                        <span
                          className="pl-2 text-sm font-bold text-appcolorred"
                          onClick={() => {
                            if (!loading) {
                              setLimit(8);
                              setCurrentPage(1);
                              setOtherFilters(null);
                            }
                          }}
                        >
                          Clear Filter
                        </span>
                      )}
                    </div>
                  </div>

                  {orders.map((item: any) => (
                    <div
                      className="p-4 mb-6 rounded-lg shadow-lg bg-appcolorlightgray"
                      key={item?.reference_code}
                    >
                      <div>
                        <p className="mb-1 font-bold">Request Details</p>
                        <p className="mb-1">
                          First Name: {item?.requester?.firstname}
                        </p>
                        <p className="mb-1">
                          Middle Name: {item?.requester?.middlename}
                        </p>
                        <p className="mb-1">
                          Last Name: {item?.requester?.lastname}
                        </p>
                        <p className="mb-1">Email: {item?.requester?.email}</p>
                        <p className="mb-1">Phone : {item?.requester?.phone}</p>
                      </div>

                      <div className="my-4">
                        <p className="mb-1 font-bold">Transaction Details</p>
                        <p className="mb-1">
                          Amount: ₦{commaNumber(item?.total_amount)}
                        </p>
                        <p className="mb-1">
                          Reference: {item?.reference_code}
                        </p>
                        <p className="mb-1">
                          Status:{" "}
                          <Tag
                            color={
                              item?.status === "Successful" ? "green" : "red"
                            }
                          >
                            {item?.status}
                          </Tag>
                        </p>
                      </div>

                      {/* <Button
                        size="small"
                        className="custom-small-btn extra-small"
                        onClick={() => {}}
                      >
                        View Request Info
                      </Button> */}
                    </div>
                  ))}

                  {orders.length > 0 && (
                    <div className="mt-8 mb-4">
                      <Pagination
                        defaultCurrent={currentPage}
                        defaultPageSize={limit}
                        total={total}
                        onChange={onChange}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      {showFilter && (
        <Transactionsfilter
          visible={showFilter}
          closeModal={closeShowFilter}
          setOtherFilters={setOtherFilters}
          isCards
        />
      )}
    </div>
  );
};

export default Orders;
