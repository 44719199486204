import OneSignal from "react-onesignal";
import { useState, useEffect } from "react";

const useOneSignal = (subscribed) => {

  const requestNotifications = async () => {
    try {
      if (!subscribed) return false;
      await OneSignal.showSlidedownPrompt();
      return true;
    } catch (error) {
      console.error("Error requesting notifications:", error);
      return false;
    }
  };

  // Set tags when user logs in or updates preferences
  const setUserTags = async (userId, userPreferences = {}) => {
    try {
      if (!subscribed) return false;

      // Set essential user identification tags
      await OneSignal.sendTags({
        user_id: userId,
        logged_in: "true",
        last_login: new Date().toISOString(),
        ...userPreferences, // Additional preferences like notification types
      });

      return true;
    } catch (error) {
      console.error("Error setting user tags:", error);
      return false;
    }
  };

  // Clear user-specific tags on logout
  const clearUserTags = async () => {
    try {
      if (!subscribed) return false;

      // List of tags to remove on logout
      const tagsToRemove = [
        "user_id",
        "logged_in",
        "last_login",
        "email",
        "account_type",
        "preferences",
        "notification_settings",
        // Add any other user-specific tags you use
      ];

      await OneSignal.deleteTags(tagsToRemove);

      // Optionally set anonymous user state
      await OneSignal.sendTags({
        user_state: "anonymous",
        last_logout: new Date().toISOString(),
      });

      return true;
    } catch (error) {
      console.error("Error clearing user tags:", error);
      return false;
    }
  };

  return {
    subscribed,
    requestNotifications,
    setUserTags,
    clearUserTags, // renamed from removeUserTags for clarity
  };
};

export default useOneSignal;
