import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Pagination, PaginationProps, message } from "antd";
import {
  getWalletTransaction,
  getWalletsDetails,
} from "../../../api/wallet/wallets";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import {
  CreditCardIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import { commaNumber, transformCurrencyToSymbol } from "../../../utils/helper";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Transactionsfilter from "./transactionsfilter";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import Generatestatement from "./generatestatement";

const Transactions = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // should now be wallet_id
  const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
  const [wallets, setWallets] = useState<any[]>([]);
  const [loadingWallets, setLoadingWallets] = useState(false);
  const [otherFilters, setOtherFilters] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);

  const [showFilter, setShowFilter] = useState(false);
  const [loadingTransactions, setloadingTransactions] = useState(false);
  const [transactions, setTransactions] = useState<any[]>([]);

  const [showGenerateStatement, setShowGenerateStatement] = useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;


  const closeShowGenerateStatement = () => {
    setShowGenerateStatement(false);
  };

  const requestData = useMemo(() => {
    let realOtherFilters = {};
    if (otherFilters !== null) {
      realOtherFilters = otherFilters;
    }
    return {
      ...realOtherFilters,
      wallet_id: selectedCurrency,
      limit: otherFilters === null ? limit : 20,
      page: otherFilters === null ? currentPage : 1,
    };
  }, [user, limit, currentPage, otherFilters, selectedCurrency]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const closeShowFilter = () => [setShowFilter(false)];

  const getTransactions = useCallback(async () => {
    setloadingTransactions(true);
    try {
      const res = await getWalletTransaction({
        ...requestData,
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          setTransactions(data);
          setTotal(parseInt(res.data.counts));
        } else {
          setTransactions([]);
        }
      } else {
        message.warning(`Get wallet transactions, ${res.data.message}`);
      }

      setloadingTransactions(false);
    } catch (error) {
      setloadingTransactions(false);
      message.error("Something went wrong: Get wallet transactions");
    }
  }, [requestData]);

  useEffect(() => {
    if (selectedCurrency !== null) {
      getTransactions();
    }

    // eslint-disable-next-line
  }, [getTransactions, selectedCurrency]);

  const getTransactionIcon = (name: string) => {
    switch (name) {
      case "Credit":
        return <CreditCardIcon />;
      case "Debit":
        return <CreditCardIcon />;
      default:
        return <ClipboardDocumentIcon />;
    }
  };

  const getWallet = async () => {
    setLoadingWallets(true);
    try {
      const res = await getWalletsDetails();

      const { status, data } = res.data;

      if (status === "success") {
        let newWallets: any = [];
        data.forEach((item: any) => {
          newWallets.push({
            ...item,
            walletLabel: `${item?.currency.toUpperCase()} WALLET - ${transformCurrencyToSymbol(
              item?.currency
            )} ${commaNumber(parseFloat(item?.available_balance).toFixed(2))}`,
          });
        });

        setWallets(newWallets);

        dispatch(
          updateUser({
            user: { ...user, wallets: data },
          })
        );

        dispatch(
          updateReloadWallet({
            reloadWallet: false,
          })
        );
      } else {
        message.warning(`Get wallets, ${res.data.message}`);
      }

      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      message.error("Something went wrong: Get wallets");
    }
  };

  useEffect(() => {
    getWallet();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-2">
      {/* <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className='w-16' />
      </div>

       */}

      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Transactions
          </h1>

          <div className="flex items-center">
            <span
              className="text-sm font-bold text-appcolorblue"
              onClick={() => {
                if (!loadingTransactions) {
                  setShowFilter(true);
                }
              }}
            >
              Filter
            </span>
            {otherFilters !== null && (
              <span
                className="pl-2 text-sm font-bold text-appcolorred"
                onClick={() => {
                  if (!loadingTransactions) {
                    setLimit(20);
                    setCurrentPage(1);
                    setOtherFilters(null);
                  }
                }}
              >
                Clear Filter
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <p
            className="font-bold cursor-pointer w-fit text-appcolorblue"
            onClick={() => {
              setShowGenerateStatement(true);
            }}
          >
            Generate Statement
          </p>
        </div>

        <div>
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Wallet"
              name="currency"
              rules={[
                {
                  required: true,
                  message: "Wallet is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Wallet"
                updateField={(_, value) => {
                  setSelectedCurrency(value);
                }}
                name={"currency"}
                selectOptions={wallets}
                loading={loadingWallets}
                selectOptionLabel="walletLabel"
                alternativeValue="wallet_id"
              />
            </Form.Item>
          </Form>
        </div>

        <div className="pt-4 pb-16">
          {loadingTransactions ? (
            <>
              {[1, 2, 3].map((item) => (
                <div className="mb-4" key={`trans-preloader-${item}`}>
                  <Skeletonloader height={"4rem"} />
                </div>
              ))}
            </>
          ) : (
            <>
              {transactions.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-2">
                  <div className="w-11 h-11">
                    <DocumentIcon className="cursor-pointer" />
                  </div>
                  <p className="text-center cursor-pointer font-poppins">
                    No transactions found.
                  </p>
                  {/* <p className="text-center cursor-pointer font-poppins">
                    Let’s get started
                  </p> */}
                </div>
              ) : (
                <div className="pb-28">
                  {transactions.map((item) => (
                    <div
                      key={item.id}
                      className="flex items-center justify-between mb-5"
                      onClick={() => {
                        navigate(
                          `/dashboard/transactions/${item?.reference_code}`,
                          {
                            state: {
                              item,
                            },
                          }
                        );
                      }}
                    >
                      <div className="flex items-center">
                        <div
                          className={`w-10 h-10 p-2 mr-2 rounded-lg 
													bg-appcolorlightgray`}
                        >
                          {item.type === "Debit" ? (
                            <ArrowUpIcon />
                          ) : (
                            <ArrowDownIcon />
                          )}
                          {/* {getTransactionIcon(item.type)} */}
                        </div>
                        <div>
                          <p className="mb-0 text-sm font-semibold font-ibmplexsans text-appcolorblack">
                            {item.type} -{" "}
                            <span
                              className={`${
                                item.status === "Pending"
                                  ? "text-appcoloryellow"
                                  : item.status === "Successful"
                                  ? "text-appcolorgreen"
                                  : item.status === "Failed"
                                  ? "text-appcolorred"
                                  : ""
                              }`}
                            ></span>
                            {item.status}
                          </p>
                          <span className="text-xs">
                            {moment(item.timestamp).format("lll")}
                          </span>
                        </div>
                      </div>

                      <div className="text-right">
                        <p
                          className={`mb-0 text-sm font-semibold font-ibmplexsans ${
                            item.status === "Pending"
                              ? "text-appcoloryellow"
                              : item.status === "Successful"
                              ? "text-appcolorgreen"
                              : item.status === "Failed"
                              ? "text-appcolorred"
                              : ""
                          }`}
                        >
                          ₦{" "}
                          {commaNumber(
                            parseFloat(item?.total_amount).toFixed(2)
                          )}
                        </p>

                        <span className="text-xs">{item.type}</span>
                      </div>
                    </div>
                  ))}

                  {transactions.length > 0 && (
                    <div className="mt-8 mb-4">
                      <Pagination
                        defaultCurrent={currentPage}
                        defaultPageSize={limit}
                        total={total}
                        onChange={onChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showFilter && (
        <Transactionsfilter
          visible={showFilter}
          closeModal={closeShowFilter}
          setOtherFilters={setOtherFilters}
        />
      )}

      <Generatestatement
        visible={showGenerateStatement}
        closeModal={closeShowGenerateStatement}
      />
    </div>
  );
};

export default Transactions;
