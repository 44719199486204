import { Alert, Button, Form, Input, message } from "antd";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  getBeneficiaries,
  getWalletsDetails,
  makeTransfer,
} from "../../../api/wallet/wallets";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import {
  commaNumber,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
} from "../../../utils/helper";
import Inputamount from "../../../components/inputamount/inputamount";
import { useNavigate } from "react-router-dom";
import { useEnabledTransactionPin } from "../../../customhooks/useEnabledTransactionPin";
import TransactionPin from "../../../components/transactionpin/transactionpin";

const Transfers = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasPinEnabled = useEnabledTransactionPin();

  const [loadingBeneficiaries, setLoadingBeneficiaries] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState<any>([]);
  const [wallets, setWallets] = useState<any[]>([]);
  const [loadingWallets, setLoadingWallets] = useState(false);
  const [errors, setErrors] = useState({
    currency: "",
    beneficiary: "",
    amount: "",
    identifier: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([
    {
      id: nanoid(),
      name: "Accounts",
      active: true,
    },
    {
      id: nanoid(),
      name: "Wallets",
      active: false,
    },
  ]);

  const [paymentSummaryDetails, setPaymentSummaryDetails] = useState<
    {
      title: string;
      value: any;
    }[]
  >([]);
  const [detailsToBeSubmitted, setDetailsToBeSubmitted] = useState<any | null>(
    null
  );
  const [showTransactionPin, setShowTransactionPin] = useState(false);

  const closeTransactionPin = () => {
    if (!loading) {
      setShowTransactionPin(false);
      setPaymentSummaryDetails([]);
      setDetailsToBeSubmitted(null);
    }
  };

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    reloadWallet: state.user.reloadWallet,
  }));

  const { user } = state;

  const switchTabs = (valueIndex: number) => {
    const newTabs = [...tabs];
    newTabs.forEach((item, index) => {
      item.active = false;
      if (valueIndex === index) {
        item.active = true;
      }
    });
    setTabs(newTabs);

    form.resetFields();
    form.setFieldValue("beneficiary", undefined);
    form.setFieldValue("identifier", undefined);
    form.setFieldsValue({
      recipient_id: undefined,
      amount: undefined,
      remark: undefined,
      currency: undefined,
      wallet_id: undefined,
    });
    setErrors({
      currency: "",
      beneficiary: "",
      amount: "",
      identifier: "",
    });
    setError(null);
    setBeneficiaries([]);

    setPaymentSummaryDetails([]);
    // setShowTransactionPin(false);
    setDetailsToBeSubmitted(null);
  };

  const constructPaymentSummaryDetails = (values: {
    remark: string;
    // currency: any;
    identifier: string;
    amount: string;
    recipient_id: string;
    wallet_id: string;
  }) => {
    const incomingBeneficiary = beneficiaries.filter(
      (item: any) => item.recipient_id === values.recipient_id
    );
		
    const detail = [
      {
        title: "Amount",
        value: `${transformCurrencyToSymbol(
          incomingBeneficiary[0]?.currency
        )}${commaNumber(values.amount)}`,
      },
      {
        title: "Account Number",
        value: incomingBeneficiary[0]?.number,
      },
      {
        title: "Bank",
        value: incomingBeneficiary[0]?.bank,
      },
      {
        title: "Name",
        value: incomingBeneficiary[0]?.name,
      },
    ];

    setPaymentSummaryDetails(detail);
  };

  const followThroughAfterPin = async (otp: string) => {
    try {
      setError(null);
      setLoading(true);

      const res = await makeTransfer(
        {
          ...detailsToBeSubmitted,
          authorization_pin: otp,
        },
        tabs[0].active ? "single-external-transfer" : "single-internal-transfer"
      );

      const { status, message, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Transfer", message);

        form.resetFields();
        setPaymentSummaryDetails([]);
        setShowTransactionPin(false);
        setDetailsToBeSubmitted(null);

        getWallet();
      } else {
        setError(message);
        openNotificationWithIcon("error", "Transfer", message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Transfer");
    }
  };

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        let dataToSend = {
          remark: values?.remark,
          // currency: values?.currency,
          identifier: values?.identifier,
          amount: values?.amount,
          recipient_id: values?.recipient_id,
          wallet_id: values?.wallet_id,
        };

        setDetailsToBeSubmitted(dataToSend);

        if (hasPinEnabled) {
          constructPaymentSummaryDetails(dataToSend);
          setLoading(false);
          setShowTransactionPin(true);
          return false;
        }

				
        const res = await makeTransfer(
          {
            ...dataToSend,
          },
          tabs[0].active
            ? "single-external-transfer"
            : "single-internal-transfer"
        );

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Transfer", message);

          form.resetFields();

          getWallet();
        } else {
          setError(message);
          openNotificationWithIcon("error", "Transfer", message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong: Transfer");
      }
    } else {
      setLoading(false);
    }
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    if (name === "wallet_id") {
      let findWallet = wallets.filter((item) => item?.wallet_id === value);
      form.setFieldValue("currency", findWallet?.[0]?.currency);
      form.setFieldsValue({
        recipient_id: undefined,
        amount: undefined,
        remark: undefined,
      });
			setPaymentSummaryDetails([]);
			// setShowTransactionPin(false);
			setDetailsToBeSubmitted(null);
    }
  };

  const getWallet = async () => {
    setLoadingWallets(true);
    try {
      const res = await getWalletsDetails();

      const { status, data } = res.data;

      if (status === "success") {
        let newWallets: any = [];
        data.forEach((item: any) => {
          newWallets.push({
            ...item,
            walletLabel: `${item?.currency.toUpperCase()} WALLET - ${transformCurrencyToSymbol(
              item?.currency
            )} ${commaNumber(parseFloat(item?.available_balance).toFixed(2))}`,
          });
        });

        setWallets(newWallets);

        dispatch(
          updateUser({
            user: { ...user, wallets: data },
          })
        );

        dispatch(
          updateReloadWallet({
            reloadWallet: false,
          })
        );
      } else {
        message.warning(`Get wallets, ${res.data.message}`);
      }

      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      message.error("Something went wrong: Get wallets");
    }
  };

  useEffect(() => {
    getWallet();

    // eslint-disable-next-line
  }, []);

  const fetchBeneficiaries = async () => {
    setLoadingBeneficiaries(true);
    try {
      const res = await getBeneficiaries({
        currency: form.getFieldValue("currency") || "",
        beneficiaryType: tabs[0].active ? "External" : "Internal",
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        let newBeneficiaries: any[] = [];
        data.forEach((item: any) => {
          newBeneficiaries.push({
            ...item,
            beneficiaryLabel: `${item?.name} - ${item?.bank} ${item?.number}`,
          });
        });
        setBeneficiaries(newBeneficiaries);
      } else {
        openNotificationWithIcon("error", "Beneficiaries", message);
      }

      setLoadingBeneficiaries(false);
    } catch (error) {
      setLoadingBeneficiaries(false);
      message.error("Something went wrong: Beneficiaries");
    }
  };

  useEffect(() => {
    if (form.getFieldValue("wallet_id") !== undefined) {
      fetchBeneficiaries();
    }
  }, [tabs, form.getFieldValue("wallet_id")]);

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Transfer
          </h1>
        </div>

        <div className="pb-16">
          <div className="grid grid-cols-2 rounded-2xl bg-appcolorshade p-[0.37rem] w-full mx-auto mb-6">
            {tabs.map((item, index) => (
              <div
                className={`font-semibold text-xs text-center cursor-pointer py-4 font-poppins ${
                  item.active ? "bg-appcolorwhite rounded-xl" : "bg-transparent"
                }`}
                onClick={() => {
                  if (!loading) {
                    switchTabs(index);
                  }
                }}
                key={item.id}
              >
                {item.name}
              </div>
            ))}
          </div>

          {error != null && (
            <div className="flex justify-center mb-4">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form onFinish={onFinish} layout="vertical" form={form}>
            <Form.Item
              validateStatus={errors?.currency.length > 0 ? "error" : undefined}
              help={errors?.currency.length > 0 ? errors.currency : undefined}
              label="Wallet"
              name="wallet_id"
              rules={[
                {
                  required: true,
                  message: "Wallet is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Wallet"
                updateField={updateField}
                name={"wallet_id"}
                selectOptions={wallets}
                loading={loadingWallets}
                selectOptionLabel="walletLabel"
                alternativeValue="wallet_id"
                selectValue={form.getFieldValue("wallet_id")}
              />
            </Form.Item>

            <>
              <Form.Item
                validateStatus={
                  errors?.beneficiary.length > 0 ? "error" : undefined
                }
                help={
                  errors?.beneficiary.length > 0
                    ? errors.beneficiary
                    : undefined
                }
                label="Beneficiary"
                name="recipient_id"
                rules={[
                  {
                    required: true,
                    message: "Wallet is required",
                  },
                ]}
              >
                <Inputselect
                  placeHolder="Select Beneficiary"
                  updateField={updateField}
                  name={"recipient_id"}
                  selectOptions={beneficiaries}
                  loading={loadingBeneficiaries}
                  selectOptionLabel="beneficiaryLabel"
                  alternativeValue="recipient_id"
                  disabled={form.getFieldValue("currency") === undefined}
                  showSearch={true}
                />
              </Form.Item>
              <p
                onClick={() => [navigate("/dashboard/beneficiary")]}
                className="mb-4 cursor-pointer text-appcolorblue w-fit"
              >
                Create Beneficiary
              </p>
            </>

            {/* {tabs[0].active ? (
              <>
                <Form.Item
                  validateStatus={
                    errors?.beneficiary.length > 0 ? "error" : undefined
                  }
                  help={
                    errors?.beneficiary.length > 0
                      ? errors.beneficiary
                      : undefined
                  }
                  label="Beneficiary"
                  name="recipient_id"
                  rules={[
                    {
                      required: true,
                      message: "Wallet is required",
                    },
                  ]}
                >
                  <Inputselect
                    placeHolder="Select Beneficiary"
                    updateField={updateField}
                    name={"recipient_id"}
                    selectOptions={beneficiaries}
                    loading={loadingBeneficiaries}
                    selectOptionLabel="beneficiaryLabel"
                    alternativeValue="recipient_id"
                    disabled={form.getFieldValue("currency") === undefined}
                    showSearch={true}
                  />
                </Form.Item>
                <p
                  onClick={() => [navigate("/dashboard/beneficiary")]}
                  className="mb-4 cursor-pointer text-appcolorblue w-fit"
                >
                  Create Beneficiary
                </p>
              </>
            ) : (
              <>
                <Form.Item
                  label="Email or Phone or Wallet ID"
                  name="identifier"
                  rules={[
                    {
                      required: true,
                      message: "Email/Phone/Wallet ID is required",
                    },
                  ]}
                >
                  <Input placeholder="Email/Phone/Wallet ID" />
                </Form.Item>
              </>
            )} */}

            <Form.Item
              validateStatus={errors?.amount.length > 0 ? "error" : undefined}
              help={errors?.amount.length > 0 ? errors.amount : undefined}
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: "Amount is required",
                },
              ]}
            >
              <Inputamount
                name="amount"
                updateField={updateField}
                placeHolder="Enter amount"
                currency={transformCurrencyToSymbol(
                  form.getFieldValue("currency")
                )}
                amountValue={form.getFieldValue("amount")}
                disabled={form.getFieldValue("currency") === undefined}
              />
            </Form.Item>

            <Form.Item
              label="Reason for Transfer or Remark"
              name="remark"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input placeholder="Reason for Transfer / Remark" />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="mb-2 "
                disabled={loading || loadingBeneficiaries || loadingWallets}
                loading={loading}
              >
                Transfer
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {showTransactionPin && (
        <TransactionPin
          paymentType="transfer"
          visible={showTransactionPin}
          closeModal={closeTransactionPin}
          loading={loading}
          paymentDetails={paymentSummaryDetails}
          onSubmit={followThroughAfterPin}
        />
      )}
    </div>
  );
};

export default Transfers;
