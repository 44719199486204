import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getPaymentCategories,
  getPaymentProducts,
  sendInitiatePayment,
  sendPaymentRequest,
} from "../../../api/payment/payment";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  commaNumber,
  convertToSlug,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
  validateNumberAbove,
} from "../../../utils/helper";
import { RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import UploadRequestInfo from "../requestloan/uploadRequestInfo";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import Inputamount from "../../../components/inputamount/inputamount";
import { IObjectKeysStringsOnly } from "../../../utils/helpers-interface";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { updateReloadWallet } from "../../../store/userSlice/userSlice";
import Paymenthistory from "./paymenthistory";
import { useEnabledTransactionPin } from "../../../customhooks/useEnabledTransactionPin";
import TransactionPin from "../../../components/transactionpin/transactionpin";

type RequestInfo = {
  name: string;
  type: string;
  option: string[];
  required: string;
};

type PaymentObject = {
  id: string;
  currency: string;
  name: string;
  description: string;
  collection: string;
  min_amount: string;
  max_amount: string;
  video: string;
  image_col_1: string;
  image_col_2: string;
  image_col_3: string;
  image_col_4: string;
  request_info: RequestInfo[];
  instalment: {
    allowed: string;
    min_count: string;
    max_count: string;
    max_duration: string;
  };
};

const payWithInstallmentOptions = [
  {
    id: "no",
    value: "no",
    label: "No",
  },
  {
    id: "yes",
    value: "yes",
    label: "Yes",
  },
];

const Payment = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasPinEnabled = useEnabledTransactionPin();

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    token: state.user.token,
  }));

  const { user, token } = state;

  const [mediaItems, setMediaItems] = useState<TabsProps["items"]>([]);
  const [viewMediaModal, setViewMediaModal] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({
    category: "",
    product: "",
    amount: "",
    installmentAmount: "",
    count: "",
    duration: "",
  });
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<PaymentObject | null>(
    null
  );

  const [loading, setLoading] = useState(false);
  const [requestInfo, setRequestInfo] = useState<
    {
      name: string;
      type:
        | "select"
        | "text"
        | "number"
        | "alphanumeric"
        | "audio"
        | "video"
        | "pdf"
        | "image";
      option: string[];
      required: string;
    }[]
  >([]);

  const [paymentSummaryDetails, setPaymentSummaryDetails] = useState<
    {
      title: string;
      value: any;
    }[]
  >([]);
  const [detailsToBeSubmitted, setDetailsToBeSubmitted] = useState<any | null>(
    null
  );
  const [initiateCallValues, setInitiateCallValues] = useState<any | null>(
    null
  );
  const [showTransactionPin, setShowTransactionPin] = useState(false);

  const closeTransactionPin = () => {
    if (!loading) {
      setShowTransactionPin(false);
      setPaymentSummaryDetails([]);
      setDetailsToBeSubmitted(null);
      setInitiateCallValues(null);
    }
  };

  const closeMediaModal = () => {
    setViewMediaModal(false);
  };

  const initiatePayment = async (referenceCode: string, formValues: any) => {
    try {
      setError(null);
      setLoading(true);

      const res = await sendInitiatePayment({
        reference_code: referenceCode,
        amount: formValues?.installmentAmount,
        partial: formValues?.payWithInstallment === "yes" ? "Yes" : "No",
        authorization_pin: formValues?.authorization_pin,
      });

      const { status, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Payment", res.data.message);
        form.resetFields();

        setPaymentSummaryDetails([]);
        setShowTransactionPin(false);
        setDetailsToBeSubmitted(null);
        setInitiateCallValues(null);

        navigate("/dashboard/payment-success");
      } else {
        openNotificationWithIcon("warning", "Payment", res.data.message);
        setError(res?.data?.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Submit Payment");
    }
  };

  const resetInstallmentStuffs = () => {
    form.setFieldsValue({
      installmentAmount: undefined,
      duration: undefined,
      count: undefined,
    });
    setErrors((prevState) => ({
      ...prevState,
      installmentAmount: "",
      duration: "",
      count: "",
    }));
  };

  const constructPaymentSummaryDetails = (values: {
    currency: string;
    request_info: {
      name: string;
      input: string;
    }[];
    id: string;
    amount: any;
    narration: string;
    count: any;
    duration: any;
  }) => {
    const detail: any = [
      {
        title: "Amount",
        value: `₦${commaNumber(values.amount)}`,
      },
      {
        title: "Product Name",
        value: selectedProduct?.name,
      },
      {
        title: "Product Collection",
        value: selectedProduct?.collection,
      },
    ];

    if (values.count) {
      detail.push({
        title: "Payment Count",
        value: values?.count,
      });
    }

    if (values.duration) {
      detail.push({
        title: "Duration",
        value: values?.duration,
      });
    }

    setPaymentSummaryDetails(detail);
  };

  const followThroughAfterPin = async (otp: string) => {
    try {
      setError(null);
      setLoading(true);

      const res = await sendPaymentRequest({
        ...detailsToBeSubmitted,
      });

      const { status, data } = res.data;

      if (status === "success") {
        await initiatePayment(data?.reference_code, {
          ...initiateCallValues,
          authorization_pin: otp,
        });
        dispatch(
          updateReloadWallet({
            reloadWallet: true,
          })
        );
        // openNotificationWithIcon(
        //   "success",
        //   "Submit Payment Request",
        //   res.data.message
        // );
      } else {
        openNotificationWithIcon("warning", "Payment", res.data.message);
        setError(res?.data?.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setLoadingServices(false);
      message.error("Something went wrong: Submit Payment Request");
    }
  };

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);

    const {
      amount,
      category,
      product,
      installmentAmount,
      payWithInstallment,
      duration,
      count,
      ...rest
    } = values;
    const request_info: {
      name: string;
      input: string;
    }[] = [];

    const resting: IObjectKeysStringsOnly = rest;

    Object.entries(resting).forEach(([key, value]) => {
      request_info.push({
        name: key?.split("-").join(" "),
        input: value,
      });
    });

    const newErrors = { ...errors };

    newErrors["amount"] = validateNumberAbove(amount, 0, "amount");

    if (selectedProduct?.max_amount !== selectedProduct?.min_amount) {
      if (Number(amount) < Number(selectedProduct?.min_amount)) {
        newErrors["amount"] = `Min amount is ₦${commaNumber(
          Number(selectedProduct?.min_amount)
        )}`;
      }
      if (Number(amount) > Number(selectedProduct?.max_amount)) {
        newErrors["amount"] = `Max amount is ₦${commaNumber(
          Number(selectedProduct?.max_amount)
        )}`;
      }
    }

    if (
      selectedProduct?.instalment?.allowed === "Yes" &&
      payWithInstallment === "yes"
    ) {
      newErrors["duration"] = validateNumberAbove(duration, 0, "duration");
      newErrors["count"] = validateNumberAbove(count, 0, "installments");

      if (Number(installmentAmount) > Number(selectedProduct?.max_amount)) {
        newErrors["installmentAmount"] = `Max amount is ₦${commaNumber(
          Number(selectedProduct?.max_amount)
        )}`;
      }

      if (Number(count) < Number(selectedProduct?.instalment?.min_count)) {
        newErrors["count"] = `Min count is ${commaNumber(
          Number(selectedProduct?.instalment?.min_count)
        )}`;
      }

      if (Number(count) > Number(selectedProduct?.instalment?.max_count)) {
        newErrors["count"] = `Max count is ${commaNumber(
          Number(selectedProduct?.instalment?.min_count)
        )}`;
      }

      if (
        Number(duration) > Number(selectedProduct?.instalment?.max_duration)
      ) {
        newErrors["duration"] = `Max duration is ${commaNumber(
          Number(selectedProduct?.instalment?.max_duration)
        )}`;
      }
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        let dataToSend = {
          currency: "ngn",
          request_info,
          id: selectedProduct?.id as string,
          amount: values?.amount,
          narration: request_info
            .map((item) => `${item.name}=${item.input}`)
            .join("-"),
          count,
          duration,
        };

        setDetailsToBeSubmitted(dataToSend);
        setInitiateCallValues(values);

        if (hasPinEnabled) {
          constructPaymentSummaryDetails(dataToSend);
          setLoading(false);
          setShowTransactionPin(true);
          return false;
        }

        const res = await sendPaymentRequest({
          ...dataToSend,
        });

        const { status, data } = res.data;

        if (status === "success") {
          await initiatePayment(data?.reference_code, values);
          dispatch(
            updateReloadWallet({
              reloadWallet: true,
            })
          );
          // openNotificationWithIcon(
          //   "success",
          //   "Submit Payment Request",
          //   res.data.message
          // );
        } else {
          openNotificationWithIcon("warning", "Payment", res.data.message);
          setError(res?.data?.message);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        // setLoadingServices(false);
        message.error("Something went wrong: Submit Payment Request");
      }
    } else {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    errorInfo.errorFields.forEach((item: any) => {
      setErrors((prevState) => ({
        ...prevState,
        [item.name[0]]: item.errors.length ? item.errors[0] : "",
      }));
    });
  };

  const renderTextWithBreaks = (text: string) => {
    return text.split(",").map((part, index) => (
      <span key={index} className="block text-sm">
        {part.trim()}
      </span>
    ));
  };

  const fetchProducts = async (category: string) => {
    setLoadingProducts(true);

    try {
      const res = await getPaymentProducts({
        currency: "ngn",
        category,
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          let newData = data.map((item: any) => ({
            ...item,
            searchText: `${item.name} ${item.collection} ${item.description}`,
            // label: `${item.name}-${item.collection}-${item.description}`,
            label: (
              <div className="flex flex-col extra-options">
                <span> {renderTextWithBreaks(item.name)}</span>
                <span> {renderTextWithBreaks(item.collection)}</span>
                {/* <span> {renderTextWithBreaks(item.description)}</span> */}
              </div>
            ),
          }));
          setProducts(newData);
        }
      } else {
        message.warning(`Get Payment Products, ${res.data.message}`);
      }

      setLoadingProducts(false);
    } catch (error) {
      setLoadingProducts(false);
      message.error("Something went wrong: Get Payment Products");
    }
  };

  const resetFormerRequestInfoFieldValues = () => {
    let getFormFieldNames = requestInfo.map((item) => convertToSlug(item.name));
    getFormFieldNames.forEach((item) => {
      form.setFieldValue(item, undefined);
    });
  };

  const constructTabMedia = (value: PaymentObject | null) => {
    let incomingArray = [
      {
        key: "video",
        value: value?.video,
        name: "Video",
      },
      {
        key: "image_col_1",
        value: value?.image_col_1,
        name: "Image 1",
      },
      {
        key: "image_col_2",
        value: value?.image_col_2,
        name: "Image 2",
      },
      {
        key: "image_col_3",
        value: value?.image_col_3,
        name: "Image 3",
      },
      {
        key: "image_col_4",
        value: value?.image_col_4,
        name: "Image 4",
      },
    ];

    let outputItems: TabsProps["items"] = incomingArray.map((item) => {
      return {
        label: item.name,
        key: item.key,
        children: (
          <div>
            {item.name.toLowerCase().includes("video") ? (
              <div className="h-[15rem]">
                <ReactPlayer
                  className="react-player"
                  url={item.value}
                  width="100%"
                  height="100%"
                  controls
                />
              </div>
            ) : (
              <div className="h-[15rem]">
                <img
                  src={item.value}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            )}
          </div>
        ),
      };
    });

    setMediaItems(outputItems);
  };

  const updateField = (name: string, value?: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (name === "category") {
      form.setFieldValue("product", undefined);
      fetchProducts(value as string);
      setProducts([]);
      resetFormerRequestInfoFieldValues();
      setRequestInfo([]);
      setSelectedProduct(null);
      resetInstallmentStuffs();

      setPaymentSummaryDetails([]);
      // setShowTransactionPin(false);
      setDetailsToBeSubmitted(null);
      setInitiateCallValues(null);
    }

    if (name === "product") {
      resetFormerRequestInfoFieldValues();
      const filterRequestInfo: any[] = products.filter(
        (item: any) => item?.id === value
      );
      setSelectedProduct(filterRequestInfo[0]);
      constructTabMedia(filterRequestInfo[0]);
      setRequestInfo(
        typeof filterRequestInfo[0]?.request_info === "string"
          ? []
          : filterRequestInfo[0]?.request_info ?? []
      );
      resetInstallmentStuffs();

      setPaymentSummaryDetails([]);
      // setShowTransactionPin(false);
      setDetailsToBeSubmitted(null);
      setInitiateCallValues(null);
    }

    if (name === "payWithInstallment") {
      resetInstallmentStuffs();
    }
  };

  const fetchCategoriesTypes = async () => {
    setLoadingCategories(true);

    try {
      const res = await getPaymentCategories();
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          setCategories(data);
        }
      } else {
        message.warning(`Get Payment Categories, ${res.data.message}`);
      }

      setLoadingCategories(false);
    } catch (error) {
      setLoadingCategories(false);
      message.error("Something went wrong: Get Payment Categories");
    }
  };

  useEffect(() => {
    fetchCategoriesTypes();
    // eslint-disable-next-line
  }, []);

  const constructFormInputs = () => {
    return (
      <>
        {requestInfo.map((item, index) => (
          <Form.Item
            key={index}
            label={item.name}
            name={convertToSlug(item.name)}
            rules={[
              {
                required: item.required === "Yes",
                message: `${
                  item?.type === "select"
                    ? "Select"
                    : item?.type === "alphanumeric" ||
                      item?.type === "number" ||
                      item?.type === "text"
                    ? "Input"
                    : "Upload"
                } is required`,
              },
            ]}
          >
            {item.type === "select" && (
              <Inputselect
                placeHolder={`Select ${item.name}`}
                updateField={updateField}
                name={convertToSlug(item.name)}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={item.option}
                optionHasValue
                loading={false}
                showSearch={false}
              />
            )}
            {item.type === "text" || item.type === "alphanumeric" ? (
              <Input
                name={convertToSlug(item.name)}
                placeholder={` ${item.name}`}
                onChange={(e: any) => {
                  updateField(convertToSlug(item.name), e.target.value);
                }}
              />
            ) : null}

            {item.type === "number" && (
              <Inputwithaddon
                updateField={updateField}
                name={convertToSlug(item.name)}
                placeHolder="8000000000"
                addonBefore="+234"
              />
            )}

            {item.type === "pdf" ||
            item.type === "image" ||
            item.type === "audio" ||
            item.type === "video" ? (
              <UploadRequestInfo
                token={token}
                type={item.type}
                name={convertToSlug(item.name)}
                submitting={loading}
                updateField={updateField}
                uploadName={item.name}
                setUploading={setUploading}
              />
            ) : null}
          </Form.Item>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (selectedProduct !== null) {
      if (selectedProduct?.min_amount === selectedProduct?.max_amount) {
        form.setFieldValue("amount", selectedProduct?.max_amount);
        setAmount(Number(selectedProduct?.max_amount));
      }
    }
  }, [selectedProduct, form.getFieldValue("amount")]);

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
          Payments
        </h1>

        <div>
          {error != null && (
            <div className="mb-3">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              validateStatus={errors?.category.length > 0 ? "error" : undefined}
              help={errors?.category.length > 0 ? errors.category : undefined}
              label="Payment Type"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Payment Type is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Payment Type"
                updateField={updateField}
                name={"category"}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={categories}
                alternativeValue={"name"}
                selectOptionLabel="name"
                loading={loadingCategories}
                showSearch={true}
              />
            </Form.Item>

            <Form.Item
              validateStatus={errors?.product.length > 0 ? "error" : undefined}
              help={errors?.product.length > 0 ? errors.product : undefined}
              label="Product Name | Collection"
              name="product"
              rules={[
                {
                  required: true,
                  message: "Product name is required",
                },
              ]}
            >
              <Inputselect
                searchInNodes={true}
                placeHolder="Select Product Name | Collection"
                updateField={updateField}
                name={"product"}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={products}
                alternativeValue={"id"}
                selectOptionLabel="label"
                loading={loadingProducts}
                showSearch={true}
                selectValue={form.getFieldValue("product")}
              />
            </Form.Item>

            {selectedProduct !== null && (
              <>
                <Form.Item label="Product Collection">
                  <Input.TextArea
                    disabled={true}
                    value={selectedProduct?.collection}
                    className="font-bold !cursor-default"
                    rows={2}
                  />
                </Form.Item>

                <Form.Item label="Product Description">
                  <Input.TextArea
                    disabled={true}
                    value={selectedProduct?.description}
                    className="font-bold !cursor-default"
                    rows={2}
                  />
                </Form.Item>
              </>
            )}

            {(selectedProduct?.video !== undefined &&
              selectedProduct?.video?.length > 0) ||
            (selectedProduct?.image_col_1 !== undefined &&
              selectedProduct?.image_col_1?.length > 0) ||
            (selectedProduct?.image_col_2 !== undefined &&
              selectedProduct?.image_col_2?.length > 0) ||
            (selectedProduct?.image_col_3 !== undefined &&
              selectedProduct?.image_col_3?.length > 0) ||
            (selectedProduct?.image_col_4 !== undefined &&
              selectedProduct?.image_col_4?.length > 0) ? (
              <div
                className="border rounded-[8px] p-2 mb-4 text-center border-indigo-200 cursor-pointer bg-blue-100"
                onClick={() => {
                  setViewMediaModal(true);
                }}
              >
                View Media
              </div>
            ) : null}

            {selectedProduct !== null && (
              <>
                <Form.Item
                  validateStatus={
                    errors?.amount.length > 0 ? "error" : undefined
                  }
                  help={errors?.amount.length > 0 ? errors.amount : undefined}
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Amount is required",
                    },
                  ]}
                >
                  <Inputamount
                    name="amount"
                    updateField={updateField}
                    placeHolder="Enter amount"
                    currency={"₦"}
                    amountValue={
                      selectedProduct?.min_amount ===
                      selectedProduct?.max_amount
                        ? amount?.toString()
                        : undefined
                    }
                    disabled={
                      selectedProduct?.min_amount ===
                      selectedProduct?.max_amount
                    }
                  />
                </Form.Item>

                {selectedProduct?.instalment?.allowed === "Yes" && (
                  <>
                    <Form.Item
                      label="Pay with installment"
                      name="payWithInstallment"
                      rules={[
                        {
                          required: true,
                          message: "Input is required",
                        },
                      ]}
                    >
                      <Inputselect
                        placeHolder="Pay with installment"
                        updateField={updateField}
                        name={"payWithInstallment"}
                        // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                        selectOptions={payWithInstallmentOptions}
                        alternativeValue={"id"}
                        selectOptionLabel="label"
                        loading={false}
                        showSearch={false}
                        // selectValue={form.getFieldValue("product")}
                      />
                    </Form.Item>

                    {form.getFieldValue("payWithInstallment") ===
                      payWithInstallmentOptions[1].id && (
                      <>
                        <Form.Item
                          validateStatus={
                            errors?.installmentAmount.length > 0
                              ? "error"
                              : undefined
                          }
                          help={
                            errors?.installmentAmount.length > 0
                              ? errors.installmentAmount
                              : undefined
                          }
                          label="Installment Amount"
                          name="installmentAmount"
                          rules={[
                            {
                              required: true,
                              message: "Installment Amount is required",
                            },
                          ]}
                        >
                          <Inputamount
                            name="installmentAmount"
                            updateField={updateField}
                            placeHolder="Enter installment amount"
                            currency={"₦"}
                          />
                        </Form.Item>

                        <Form.Item
                          validateStatus={
                            errors?.duration.length > 0 ? "error" : undefined
                          }
                          help={
                            errors?.duration.length > 0
                              ? errors.duration
                              : undefined
                          }
                          label="Duration (days)"
                          name="duration"
                          rules={[
                            {
                              required: true,
                              message: "Duration is required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Duration in days"
                            min="1"
                            type="number"
														onChange={(e) => {
															updateField('duration', e.target.value)
														}}
                          />
                        </Form.Item>

                        <Form.Item
                          validateStatus={
                            errors?.count.length > 0 ? "error" : undefined
                          }
                          help={
                            errors?.count.length > 0 ? errors.count : undefined
                          }
                          label="How many time do you want to repay?"
                          name="count"
                          rules={[
                            {
                              required: true,
                              message: "Input field is required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="How many time do you want to repay?"
                            min="1"
                            type="number"
														onChange={(e) => {
															updateField('count', e.target.value)
														}}
                          />
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {constructFormInputs()}

            <Form.Item>
              <div className="flex flex-col items-center justify-center mx-auto mt-0 mb-40">
                <Button
                  htmlType="submit"
                  className="mb-2"
                  disabled={
                    loading || loadingCategories || uploading || loadingProducts
                  }
                  loading={loading}
                >
                  Make Payment
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>

      {viewMediaModal && (
        <Modal
          open={viewMediaModal}
          footer={null}
          onCancel={closeMediaModal}
          onOk={closeMediaModal}
          destroyOnClose
        >
          <div>
            <div>
              <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
                Media
              </h1>

              <p className="mb-2 font-poppins">
                Here is a list of all the videos and images related to this
                product{" "}
              </p>

              <div>
                <Tabs
                  defaultActiveKey={mediaItems?.[0]?.key}
                  items={mediaItems}
                  //  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showTransactionPin && (
        <TransactionPin
          paymentType="payment"
          visible={showTransactionPin}
          closeModal={closeTransactionPin}
          loading={loading}
          paymentDetails={paymentSummaryDetails}
          onSubmit={followThroughAfterPin}
        />
      )}
    </div>
  );
};

export default Payment;
