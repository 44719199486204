import React, { useEffect, useMemo, useState } from "react";
import { accountVerification } from "./accounts-interface";
import { Alert, Button, Form, Input, Upload, message } from "antd";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import { restructureLabel } from "./accountverification";
import Inputselect from "../../../components/inputselect/inputselect";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import { openNotificationWithIcon, updateCommonItems } from "../../../utils/helper";
import { updateUser } from "../../../store/userSlice/userSlice";
import {
  handleBusinessDocumentVerification,
  handleSignatureVerification,
} from "../../../api/base/base";
import { UploadOutlined } from "@ant-design/icons";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const Verifybusinessdocument = ({
  submitting,
  setSubmitting,
  verificationType,
  setVerificationTodo,
  verificationTodo,
}: accountVerification) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const [url, setUrl] = useState("");
  const [errors, setErrors] = useState({
    phone: "",
  });

  const state = useSelector((state: RootState) => ({
    token: state.user.token,
    user: state.user.user,
  }));

  const { token, user } = state;

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("PDF must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length) {
      if (newFileList[0].status === "uploading") {
        setLoading(true);
        setImageUrl("");
        setUrl("");
      }
      if (newFileList[0].status === "done") {
        setLoading(false);
        if (newFileList[0].response.status === "success") {
          message.success("Business Documents uploaded successfully");
          setUrl(newFileList[0]?.response?.data);
        } else {
          openNotificationWithIcon(
            "error",
            "Upload Business Documents",
            newFileList[0]?.response?.message
          );
        }

        // Get this url from response in real world.
        getBase64(newFileList[0].originFileObj as RcFile, (url) => {
          setLoading(false);
          setImageUrl(url);
        });
      }
      if (newFileList[0].status === "error") {
        setLoading(false);
        openNotificationWithIcon(
          "error",
          "Upload Business Documents",
          "Business Documents could not be uploaded"
        );
      }
    }
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (url.length > 0) {
      updateField("url", url);
    }
  }, [url]);

  const onFinish = async (values: any) => {
    const { phone } = values;
    setError(null);
    setSubmitting(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await handleBusinessDocumentVerification({
          document_url: values?.url,
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            "Verify Business Documents",
            message
          );

          dispatch(
            updateUser({
              user: data,
            })
          );

          let newVerificationTodo: any[] = [];

          newVerificationTodo = updateCommonItems(
            verificationTodo,
            data?.kyc?.details
          );

          setVerificationTodo(newVerificationTodo);
          // }
        } else {
          setError(message);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error("Something went wrong: Verify Business Documents");
      }
    } else {
      setSubmitting(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (fileList.length === 0) {
      form.setFieldValue("url", undefined);
    }
  }, [fileList]);

  return (
    <div>
      {error != null && (
        <div className="mb-2">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <div className="mb-2">
        <Alert
          message={
            "A merge CAC document including; certificate, shareholder details, Mermat and important company details"
          }
          type="info"
          className="w-full"
        />
      </div>

      <Form
        className="w-full"
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="url"
          rules={[
            {
              required: true,
              message: "Business Documents is required",
            },
          ]}
          label={<span>Upload Business Documents</span>}
        >
          <Upload
            fileList={fileList}
            disabled={submitting}
            maxCount={1}
            accept={".pdf"}
            beforeUpload={beforeUpload}
            name="file"
            listType="picture-card"
            className="document-uploader"
            onChange={handleChange}
            action={`${REACT_APP_BASE_URL}/misc/file-upload`}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            showUploadList={true}
          >
            {/* {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )} */}
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            className="mb-2 "
            disabled={submitting || loading}
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Verifybusinessdocument;
