import React, { useEffect, useMemo, useState } from "react";
import { accountVerification } from "./accounts-interface";
import { Alert, Button, Form, Input, Upload, message } from "antd";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import { restructureLabel } from "./accountverification";
import Inputselect from "../../../components/inputselect/inputselect";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import {
  openNotificationWithIcon,
  updateCommonItems,
} from "../../../utils/helper";
import { updateUser } from "../../../store/userSlice/userSlice";
import { handleNextofKinVerification } from "../../../api/base/base";

// const relationships = [
//   "Father",
//   "Mother",
//   "Sister",
//   "Brother",
//   "Aunt",
//   "Uncle",
//   "Son",
//   "Daughter",
//   "Wife",
//   "Husband",
// ];

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const Verifynextofkin = ({
  submitting,
  setSubmitting,
  verificationType,
  setVerificationTodo,
  verificationTodo,
}: accountVerification) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [chosenRelationship, setChosenRelationship] = useState("");
  const [url, setUrl] = useState("");
  const [error, setError] = useState(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [errors, setErrors] = useState({
    phone: "",
    relationship: "",
  });
  const [currentRelation, setCurrentRelation] = useState<any>(null);

  const state = useSelector((state: RootState) => ({
    token: state.user.token,
    user: state.user.user,
  }));

  const { token, user } = state;

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0].status === "uploading") {
      setLoading(true);
      setImageUrl("");
      setUrl("");
    }
    if (newFileList[0].status === "done") {
      setLoading(false);
      if (newFileList[0].response.status === "success") {
        message.success("Selfie uploaded successfully");
        setUrl(newFileList[0]?.response?.data);
      } else {
        openNotificationWithIcon(
          "error",
          "Upload Image",
          newFileList[0]?.response?.message
        );
      }

      // Get this url from response in real world.
      getBase64(newFileList[0].originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
    if (newFileList[0].status === "error") {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Upload Image",
        "Image could not be uploaded"
      );
    }
  };

  useEffect(() => {
    updateField("url", url);
  }, [url]);

  const onFinish = async (values: any) => {
    setError(null);
    setSubmitting(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await handleNextofKinVerification(
          {
            photo_url: values?.url,
            option: values?.relationship,
            country_code: "NG",
            phone: `+234${values?.phone}`,
            number: values?.number,
          },
          verificationType.replace(/_/g, "-")
        );

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            `Verify ${restructureLabel(verificationType)}`,
            message
          );

          dispatch(
            updateUser({
              user: data,
            })
          );

          let newVerificationTodo: any[] = [];

          newVerificationTodo = updateCommonItems(
            verificationTodo,
            data?.kyc?.details
          );

          setVerificationTodo(newVerificationTodo);
        } else {
          setError(message);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error(
          `Something went wrong: Verify ${restructureLabel(verificationType)}`
        );
      }
    } else {
      setSubmitting(false);
    }
  };

  const availableOptions = useMemo(() => {
    const getVerificationOption = verificationTodo?.filter(
      (item) => item.parameter === verificationType
    )[0].option;
    const refixOptions: any[] = [];
    getVerificationOption.forEach((item: string) => {
      refixOptions.push(item);
    });
    return refixOptions;
  }, [verificationTodo]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    setImageUrl("");
    setFileList([]);
    setUrl("");
  }, [chosenRelationship]);

  return (
    <div>
      {error != null && (
        <div className="mb-2">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item
          validateStatus={errors?.relationship.length > 0 ? "error" : undefined}
          help={
            errors?.relationship.length > 0 ? errors.relationship : undefined
          }
          label="Relationship"
          name="relationship"
          rules={[
            {
              required: true,
              message: "Relationship is required",
            },
          ]}
        >
          <Inputselect
            placeHolder="Choose Relationship"
            updateField={updateField}
            name={"relationship"}
            selectOptions={availableOptions}
            optionHasValue={true}
          />
        </Form.Item>

        <Form.Item
          validateStatus={errors?.phone.length > 0 ? "error" : undefined}
          help={errors?.phone.length > 0 ? errors.phone : undefined}
          label={`${restructureLabel(verificationType)} Phone Number`}
          name="phone"
          rules={[
            {
              required: true,
              message: "Phone Number is required",
            },
          ]}
        >
          <Inputwithaddon
            updateField={updateField}
            name="phone"
            placeHolder="8000000000"
            addonBefore="+234"
          />
        </Form.Item>

        {form.getFieldValue("relationship") ? (
          <>
            {verificationType === "secondary_guarantor" ||
            verificationType === "primary_guarantor" ? (
              <Form.Item
                label="BVN"
                name="number"
                rules={[
                  {
                    required: true,
                    message: "BVN is required",
                  },
                ]}
              >
                <Input placeholder="BVN" />
              </Form.Item>
            ) : (
              <Form.Item
                name="url"
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: `${convertText(
                    //     form.getFieldValue("verificationOption")
                    //   )} is required`,
                    // },
                  ]
                }
                label={
                  <span>Upload {form.getFieldValue("relationship")} Image</span>
                }
              >
                <Upload
                  disabled={submitting}
                  maxCount={1}
                  accept={".png, .jpg, .jpeg"}
                  beforeUpload={beforeUpload}
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  onChange={handleChange}
                  action={`${REACT_APP_BASE_URL}/misc/file-upload`}
                  headers={{
                    Authorization: `Bearer ${token}`,
                  }}
                  showUploadList={false}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            )}
          </>
        ) : null}

        <Form.Item>
          <Button
            htmlType="submit"
            className="mb-2 "
            disabled={submitting || loading}
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Verifynextofkin;
